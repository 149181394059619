import React, { useState, useEffect } from "react";
import convertLottieToCSS from "./convertLottieToCSS";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import { format } from "prettier/standalone";
import parserCSS from "prettier/parser-postcss";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-css";
import "prismjs/themes/prism.css";
import cssIcon from "../../Images/Icons/css.svg";
import copyIcon from "../../Images/Icons/copy.svg";
// import lottieToReact from '../CodeConversion/React/LottieToReact'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function LottieToCSS(props) {
  const [cssCode, setCSSCode] = useState("");
  const [lottieData, setLottieData] = useState("");

  useEffect(() => {
    if (Object.keys(props.animationData).length > 0) {
      setLottieData(props.animationData);
      const formattedCSS = format(props.cssCode, {
        parser: "css",
        plugins: [parserCSS],
        singleQuote: true,
        tabWidth: 2,
      });
      setCSSCode(formattedCSS);
     
    }
  }, [props.animationData, props.cssCode]);

  return (
    <div style={{ backgroundColor: "rgb(30, 30, 30)" }}>
      <SyntaxHighlighter language="css" style={vscDarkPlus}>
        {cssCode}
      </SyntaxHighlighter>
    </div>
  );
}
