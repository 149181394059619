import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import lottie_api from "lottie-api";
import { app, auth, db } from './Auth/firebaseApp/firebaseApp';
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore";
import './spec.css';
import Header from './Header/Header';
import "./Timeline/AnimationTimeline.css";
import CodeHeader from "./CodeConversion/CodeHeader/CodeHeader";
import LottieToCSS from "./CodeConversion/CSS/LottieToCSS";
import LottieToFluent from "./CodeConversion/Fluent/LottieToFluent";
import LottieToReact from "./CodeConversion/React/LottieToReact";
import LottieToHTML from "./CodeConversion/HTML/LottieToHTML";
import LottieToPrettyJSON from './CodeConversion/JSON/LottieToPrettyJSON'
import AnimationTimeline from "./Timeline/AnimationTimeline";
import hotkeys from 'hotkeys-js';
import ColorPickerIcon from './Images/Icons/colorPickerIcon.svg';
import LiveHTML from './LiveCode/LiveHTML';
import CTI from "./Timeline/CTI/CTI";
import PropertiesPanel from './PropertiesPanel/PropertiesPanel';
import magnifyIcon from './Images/Icons/magnify.svg';
import SpecLoader from '../src/Loaders/SpecLoader'
import demagnifyIcon from './Images/Icons/demagnify.svg';
import PlayIcon from "./Images/Icons/play.svg";
import PauseIcon from "./Images/Icons/pause.svg";
import LoopOff from "./Images/Icons/loop_off.svg"
import LoopOn from "./Images/Icons/loop_on.svg"

const Spec = (props) => {
    const [lottieData, setLottieData] = useState(null);
    const [lottiePlayerData, setLottiePlayerData] = useState(null);
    const [codePreviewMain, setCodePreviewMain] = useState('fluent');
    const [codePreviewSecondary, setCodePreviewSecondary] = useState('css');
    const [codeSplit, setCodeSplit] = useState(true);
    const [loop, setLoop] = useState(false);
    const [firstRun, setFirstRun] = useState(true);
    const [layout, setLayout] = useState(true);
    const [specID, setSpecID] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [totalFrames, setTotalFrames] = useState(null);
    const [frameRate, setFrameRate] = useState(null);
    const [viewMode, setViewMode]= useState('user');
    const [animDuration, setAnimDuration] = useState(null);
    const [openLiveProto, setOpenLiveProto] = useState(false);
    const [orginalLottieData, setOriginalLottieData] = useState(null);
    const [isSolo, setIsSolo] = useState(false);
    const [layerInfo, setLayerInfo] = useState(null);
    const [curLayer, setCurLayer] = useState(null);
    const [playerLoaded, setPlayerLoaded] = useState(null);
    const [selectedLayerIndex, setSelectedLayerIndex] = useState(false);
    const [prettyAnim, setPrettyAnim] = useState(null);
    //const [animation, setAnimation] = useState(null);
    const [playhead, setPlayhead] = useState(null);
    const [currentFrame, setCurrentFrame] = useState(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [time, setTime] = useState(0);
    const [notes, setNotes] = useState("")
    const [reactCode, setReactCode] = useState(null);
    const [cssCode, setCSSCode] = useState("");
    const [fluentCode, setFluentCode] = useState(null);
    const [htmlCode, setHTMLCode] = useState(null);
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [lottiePreviewBG, setLottiePreviewBG] = useState('transparent');
    const animationContainer = useRef(null);
    const animation = useRef(null);
    const [initialLottieData, setInitialLottieData] = useState(null);
    const [modifiedLottieData, setModifiedLottieData] = useState(lottieData);
    const [soloLayerIndex, setSoloLayerIndex] = useState(null);



    useEffect(() => {
        //Pull Spec ID From URL params
        const url = window.location.href;
        const spec_id = url.split('/').pop();
        if (props.lottieData) {
            // setSpecID(props.setSpecID)
            setSpecID(props.spec_id)
            setNotes(props.notes)
            getSpec(props.spec_id)
            // setLottieData(JSON.parse(props.lottieData))

        } else {
            setSpecID(spec_id)
            getSpec(spec_id)
        }
    }, [])

    useEffect(() => {
        setViewMode(props.view_mode)
    }, [props.view_mode])

    

    // const handleEnterFrame = (event) => {
    //     var newCurrentTime = animation.current.currentFrame / animation.current.frameRate;

    //     setTime(newCurrentTime)
    // };

    // const handleEnterFrame = () => {
    //     requestAnimationFrame(() => {
    //         if (animation.current) {

    //             setTime(animation.current.currentFrame / animation.current.frameRate);
    //         }
    //     });
    // };


    // const playheadRef = useRef(null); // Reference for the playhead input
    //const currentTime = useRef(0);
    // const lastUpdateTime = useRef(0);

    // const handleEnterFrame = () => {
    //     requestAnimationFrame(() => {
    //         if (animation.current) {
    //             currentTime.current = animation.current.currentFrame / animation.current.frameRate;

    //             const now = performance.now();
    //             if (now - lastUpdateTime.current > 300) { // Throttle updates to 300ms
    //                 lastUpdateTime.current = now;
    //                 if (playheadRef.current) {
    //                     playheadRef.current.value = currentTime.current; 

    //                 }
    //             }
    //         }
    //     });
    // };

    const playheadRef = useRef(null); // Ref for slider input
    const currentTime = useRef(0);
    const timeRef = useRef(0); // Stores current time in ms
    const lastUpdateTime = useRef(0);
    const timeDisplayRef = useRef(null); // Reference for updating the time text

    // Function to update time and playhead
    const handleEnterFrame = () => {
        requestAnimationFrame(() => {
            if (animation.current) {
                const now = performance.now();

                // Calculate current time in ms
                timeRef.current = (animation.current.currentFrame / animation.current.frameRate) * 1000;

                // Throttle UI updates (every 300ms)
                if (now - lastUpdateTime.current > 300) {
                    lastUpdateTime.current = now;

                    // Update slider value
                    if (playheadRef.current) {
                        playheadRef.current.value = animation.current.currentFrame / animation.current.frameRate;
                    }

                    // Update time display without re-rendering
                    if (timeDisplayRef.current) {
                        timeDisplayRef.current.textContent = `${timeRef.current.toFixed(0)}ms`;
                    }
                }

                // Continue updating
                handleEnterFrame();
            }
        });
    };

    useEffect(() => {
        if (animation.current) {
            animation.current.addEventListener("enterFrame", handleEnterFrame);
        }

        return () => {
            if (animation.current) {
                animation.current.removeEventListener("enterFrame", handleEnterFrame);
            }
        };
    }, [animation]);



    useEffect(() => {
        if (lottieData && lottiePlayerData) {
            let animData = prependToLayerNames(lottieData);
            
            let animDataPlayer = prependToLayerNames(lottiePlayerData)
            // console.log(scrubbedData)
            setLottieData(animData);
            setLottiePlayerData(animDataPlayer)
            // Load the animation
            animation.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: "svg",
                loop: false,
                autoplay: isPlaying,
                animationData: animDataPlayer,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                    filterSize: {
                        width: '200%',
                        height: '200%',
                        x: '-50%',
                        y: '-50%',
                      }
                }
               
            });

            // Set up event listener for when the animation completes
            animation.current.addEventListener("complete", () => {
                // Reset to frame 0
                animation.current.goToAndStop(0, true);

                // Calculate the timeout duration (animation duration + 2 seconds)
                const timeoutDuration = (animation.current.getDuration() * 1000) + 3000;
                setIsPlaying(false)
                // Set `setPlayerLoaded(true)` after the timeout
                setTimeout(() => {
                    setPlayerLoaded(true);

                }, timeoutDuration);
            });

            // Set up other event listeners and initialization logic
            animation.current.addEventListener("DOMLoaded", () => {
                // No additional logic here since `setPlayerLoaded(true)` will be handled by the complete event
            });

            animation.current.addEventListener('enterFrame', handleEnterFrame);

            hotkeys('space', function (event, handler) {
                event.preventDefault();

                if (animation.current.isPaused) {
                    animation.current.play();
                    setIsPlaying(true);
                } else {
                    setIsPlaying(false);
                    animation.current.pause();
                }
            });

            if (firstRun) {
                let duration = animation.current.getDuration();
                const totalFrames = duration * animation.current.frameRate;
                setFrameRate(animation.current.frameRate);
                setTotalFrames(totalFrames);
                setOriginalLottieData(lottieData);
                setAnimDuration(duration);
                setCurLayer(lottieData.layers);
                setFirstRun(false);
            }

            // Clean up when the component unmounts or when lottieData changes
            return () => {
                if (animation.current) {
                    animation.current.destroy();
                }
            };
        }
    }, [lottieData]);






    const openProtoWindow = () => {
        LiveHTML(htmlCode, cssCode)
    }
    // const prependToLayerNames = (json) => {
    //     if (typeof json === 'object' && json !== null) {
    //         for (let key in json) {
    //             if (key === 'nm' && typeof json[key] === 'string') {
    //                 // Prepend a '#' to the nm field if it doesn't already start with it
    //                 if (!json[key].startsWith('#')) {
    //                     json[key] = '#' + json[key];
    //                 } else {
    //                     let count = 0;
    //                     while (json[key].startsWith('#', count)) {
    //                         count++;
    //                     }
    //                     json[key] = '#' + json[key].slice(count);
    //                 }

    //                 // Apply cleanName to nm field without the leading '#'
    //                 const cleanedName = cleanName(json[key].substring(1));

    //                 // Append the ind or ix value to nm and ln fields
    //                 if ('ind' in json) {
    //                     json[key] = '#' + cleanedName + "_" + json['ind'];
    //                     json['ln'] = cleanedName + "_" + json['ind'];
    //                 } else if ('ix' in json) {
    //                     json[key] = '#' + cleanedName + "_" + json['ix'];
    //                     json['ln'] = cleanedName + "_" + json['ix'];
    //                 }
    //             } else if (Array.isArray(json[key])) {
    //                 json[key].forEach(item => prependToLayerNames(item));
    //             } else if (typeof json[key] === 'object') {
    //                 prependToLayerNames(json[key]);
    //             }
    //         }
    //     }
    //     return json; // Return the updated json
    // }


    // function adjustGradientX1(lottieJSON) {
    //     // Create a deep copy to avoid modifying the original JSON
    //     const modifiedJSON = JSON.parse(JSON.stringify(lottieJSON));
    
    //     // Set to store used random offsets (rounded to 4 decimal places)
    //     const usedOffsets = new Set();
    
    //     // Function to generate a unique random value between 0.001 and 0.009
    //     const getUniqueRandomOffset = () => {
    //         const precision = 4; // Consistent precision
    //         let randomOffset;
    //         do {
    //             randomOffset = 0.001 + Math.random() * (0.009 - 0.001);
    //             randomOffset = Number(randomOffset.toFixed(precision));
    //         } while (usedOffsets.has(randomOffset) && usedOffsets.size < 9);
    
    //         if (usedOffsets.size >= 9) return randomOffset; // Fallback
    //         usedOffsets.add(randomOffset);
    //         return randomOffset;
    //     };
    
    //     // Process each layer
    //     modifiedJSON.layers.forEach(layer => {
    //         if (layer.ty !== 4 || !layer.shapes) return;
    
    //         layer.shapes.forEach(shapeGroup => {
    //             if (shapeGroup.ty !== "gr") return;
    
    //             shapeGroup.it.forEach(shape => {
    //                 if (shape.ty !== "gf") return;
    
    //                 if (shape.s.a === 0) {
    //                     if (Array.isArray(shape.s.k) && shape.s.k.length >= 1) {
    //                         shape.s.k[0] = Number((shape.s.k[0] + getUniqueRandomOffset()).toFixed(4));
    //                     }
    //                 } else if (shape.s.a === 1 && Array.isArray(shape.s.k) && shape.s.k.length > 0) {
    //                     const firstKeyframe = shape.s.k[0];
    //                     if (firstKeyframe.s && Array.isArray(firstKeyframe.s) && firstKeyframe.s.length >= 1) {
    //                         firstKeyframe.s[0] = Number((firstKeyframe.s[0] + getUniqueRandomOffset()).toFixed(4));
    //                     }
    //                 }
    //             });
    //         });
    //     });
    
    //     return JSON.stringify(modifiedJSON);
    // }
 
    function adjustGradientX1(lottieJSON) {
        const modifiedJSON = JSON.parse(JSON.stringify(lottieJSON));
        const usedOffsets = new Set();
    
        const getUniqueRandomOffset = () => {
            const precision = 4;
            let randomOffset;
            do {
                randomOffset = 0.001 + Math.random() * (0.009 - 0.001);
                randomOffset = Number(randomOffset.toFixed(precision));
            } while (usedOffsets.has(randomOffset) && usedOffsets.size < 9);
            usedOffsets.add(randomOffset);
            return randomOffset;
        };
    
        modifiedJSON.layers.forEach(layer => {
            if (layer.ty !== 4 || !layer.shapes) return;
            layer.shapes.forEach(shapeGroup => {
                if (shapeGroup.ty !== "gr") return;
                shapeGroup.it.forEach(shape => {
                    if (shape.ty !== "gf") return;
                    if (shape.s.a === 0) {
                        if (Array.isArray(shape.s.k) && shape.s.k.length >= 1) {
                            shape.s.k[0] = Number((shape.s.k[0] + getUniqueRandomOffset()).toFixed(4));
                        }
                    } else if (shape.s.a === 1 && Array.isArray(shape.s.k) && shape.s.k.length > 0) {
                        const firstKeyframe = shape.s.k[0];
                        if (firstKeyframe.s && Array.isArray(firstKeyframe.s) && firstKeyframe.s.length >= 1) {
                            firstKeyframe.s[0] = Number((firstKeyframe.s[0] + getUniqueRandomOffset()).toFixed(4));
                        }
                    }
                });
            });
        });
    
        return JSON.stringify(modifiedJSON); // Return object, not string
    }
     
    const prependToLayerNames = (json) => {
        if (typeof json === 'object' && json !== null) {
            for (let key in json) {
                if (key === 'nm' && typeof json[key] === 'string') {
                    // Prepend a '#' to the nm field if it doesn't already start with it
                    if (!json[key].startsWith('#')) {
                        json[key] = '#' + json[key];
                    } else {
                        let count = 0;
                        while (json[key].startsWith('#', count)) {
                            count++;
                        }
                        json[key] = '#' + json[key].slice(count);
                    }
    
                    // Apply cleanName to nm field without the leading '#'
                    const cleanedName = cleanName(json[key].substring(1));
    
                    // Append the ind or ix value to nm and ln fields
                    if ('ind' in json) {
                        json[key] = '#' + cleanedName + "_" + json['ind'];
                        json['ln'] = cleanedName + "_" + json['ind'];
                    } else if ('ix' in json) {
                        json[key] = '#' + cleanedName + "_" + json['ix'];
                        json['ln'] = cleanedName + "_" + json['ix'];
                    }
                }
    
                // Handle strokes ("ty": "st") that might be missing "ln"
                if (json["ty"] === "st" && "nm" in json) {
                    const cleanedName = cleanName(json["nm"].substring(1));
                    if ('ix' in json) {
                        json['ln'] = cleanedName + "_" + json['ix'];
                    } else {
                        json['ln'] = cleanedName; // Fallback if no index exists
                    }
                }
    
                // Recursively process arrays and objects
                if (Array.isArray(json[key])) {
                    json[key].forEach(item => prependToLayerNames(item));
                } else if (typeof json[key] === 'object') {
                    prependToLayerNames(json[key]);
                }
            }
        }
        return json; // Return the updated JSON
    };
    

    // cleanName function
    const cleanName = (name) => {
        return name.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');
    }


    const toggleSoloLayer = (layerName, layerIndex) => {

        if (!animation.current) return;

        // Capture current frame before modifying animation data
        const currentFrame = animation.current.currentFrame;

        if (soloLayerIndex === layerIndex) {

            // Restore original Lottie data
            setModifiedLottieData(initialLottieData || lottieData);
            setSoloLayerIndex(null);

            // Destroy and reinitialize with original animation
            animation.current.destroy();
            animation.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: "svg",
                loop: loop,
                autoplay: isPlaying,
                animationData: {
                    ...lottiePlayerData,
                    layers: [...lottiePlayerData.layers].sort((a, b) => a.ind - b.ind), // Restore original order
                },
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                    filterSize: {
                        width: '200%',
                        height: '200%',
                        x: '-50%',
                        y: '-50%',
                      }
                }
            });

            // Reattach event listeners
            animation.current.addEventListener('enterFrame', handleEnterFrame);
            animation.current.addEventListener('complete', resetPlayhead);

            // Resume at the same frame
            animation.current.goToAndPlay(currentFrame, true);
            return;
        }

        // Save original Lottie data only once
        if (!initialLottieData) setInitialLottieData(lottiePlayerData);

        // Deep copy Lottie data
        const newLottieData = JSON.parse(JSON.stringify(lottiePlayerData));

        // Modify opacity: Show only the selected layer, hide others
        newLottieData.layers.forEach((layer, index) => {
            // layer.op = index === layerIndex ? 100 : 0; // 100 = fully visible, 0 = hidden

            if (layer.ln !== layerName) {
                if (layer.ks.o.a) {
                    // console.log(layer.ks.o)
                    layer.ks.o.k.forEach((keyframe) => {
                        keyframe.s = 0
                    })
                } else {
                    console.log(layer.ks.o)
                    layer.ks.o.k = 0
                }

            }

        });

        // Preserve original order by sorting based on 'ind'
        // newLottieData.layers.sort((a, b) => a.ind - b.ind);

        // Update state
        setModifiedLottieData(newLottieData);
        setSoloLayerIndex(layerIndex);

        // Destroy old animation and reload new one
        animation.current.destroy();
        animation.current = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: loop,
            autoplay: isPlaying,
            animationData: newLottieData, // Load modified animation
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        });

        // Reattach event listeners
        animation.current.addEventListener('enterFrame', handleEnterFrame);
        animation.current.addEventListener('complete', resetPlayhead);

        // Resume at the same frame
        animation.current.goToAndPlay(currentFrame, true);
    };



    // Function to reset the playhead when animation completes
    const resetPlayhead = () => {
        if (animation.current) {
            animation.current.goToAndStop(0, true); // Reset to frame 0
            currentTime.current = 0; // Update the ref
            if (playheadRef.current) {
                playheadRef.current.value = 0; // Reset playhead UI
            }
            setIsPlaying(false); // Ensure it does not auto-play after reset
        }
    };









    const getSpec = async (spec_id) => {
        // Fetch specs from Firebase
        const q = query(collection(db, "specs"), where("spec_id", "==", spec_id));
        const querySnapshot = await getDocs(q);
        let specAry = [];
        querySnapshot.forEach((doc) => {
            if (Array.isArray(doc.data())) {
                let scrubbedData=adjustGradientX1(JSON.parse(doc.data()[0].lottieData))
                setLottieData(JSON.parse(scrubbedData))
                // setLottiePlayerData(JSON.parse(doc.data()[0].lottieData))
                setLottiePlayerData(JSON.parse(scrubbedData))

            } else {
                
                let scrubbedData=adjustGradientX1(JSON.parse(doc.data().lottieData))
                
                // setLottieData(JSON.parse(doc.data().lottieData))
                // setLottiePlayerData(JSON.parse(doc.data().lottieData))
                setLottieData(JSON.parse(scrubbedData))
                setLottiePlayerData(JSON.parse(scrubbedData))
                setNotes(doc.data().notes)
                setLottiePreviewBG(doc.data().lottiePreviewBG)
            }
        });
        //setLottieData(JSON.parse(specAry.lottieData));
    };




    const togglePlay = () => {
        if (animation) {
            if (isPlaying) {
                animation.current.pause()
            } else {
                animation.current.play()
            }
            setIsPlaying(!isPlaying);

        }
    };

    const toggleLoop = () => {
        if (animation?.current) {
            // Get current loop state
            const currentLoop = animation.current.loop;

            // Toggle loop state
            animation.current.loop = !currentLoop;

            setLoop(animation.current.loop)

        }
    };



    // const handleTimeChange = (e) => {
    //     animation.current.goToAndStop(e.target.value * lottieData.fr, true);
    //     setTime(e.target.value);
    //     setIsPlaying(false)

    // };
    const handleTimeChange = (e) => {
        if (!animation.current) return;

        const newTime = parseFloat(e.target.value); // Ensure it's a number
        const newFrame = newTime * lottieData.fr; // Convert to frames

        currentTime.current = newTime; // Update time reference

        if (playheadRef.current) {
            playheadRef.current.value = newTime; // Update input UI without re-rendering
        }

        animation.current.goToAndStop(newFrame, true); // Seek animation without playing
        setIsPlaying(false); // Pause playback when seeking
    };





    const deleteNode = React.useCallback(() => {
        // logic here
    }, [])

    const handlers = {
        DELETE_NODE: deleteNode
    };


    const updateCurLayer = (layer) => {
        setSelectedLayerIndex(layer.ix)
        setCurLayer(layer)
    }

    const handleLayoutChange = () => {

        setLayout(!layout)

    }

    const zoomAnimation = (zoomIncrement) => {

        let curZoom = zoom
        let newZoom = curZoom + zoomIncrement
        if (newZoom > 0) {
            setZoom(newZoom)
        }

    }



    const updateFluentCode = (code) => {
        //console.log(code)
        setFluentCode(code)
    }
    const updateCSSCode = (code) => {
        //console.log(code)
        setCSSCode(code)
    }

    const changePreiveBgColor = (color) => {
        setLottiePreviewBG(color)
    }

    const handleCodePreviewChange = (code, bucket) => {
        if (bucket === "main") {
            setCodePreviewMain(code)
        }
        if (bucket === "secondary") {
            setCodePreviewSecondary(code)
        }
    }

    const handleCodeSplitChange = () => {
        setCodeSplit(!codeSplit)
    }

    const copyCodeToClipboard = (bucket) => {
      
        if (bucket === 'main') {
            if (codePreviewMain === 'html') {
                copyCode(htmlCode)
            }
            if (codePreviewMain === 'react') {
                copyCode(reactCode)
            }
            if (codePreviewMain === 'fluent') {
                copyCode(fluentCode)
            }
            if (codePreviewMain === 'css') {
                copyCode(cssCode)
            }
            if (codePreviewMain === 'json') {
                copyCode(JSON.stringify(lottieData))
            }
        }

        if (bucket === 'secondary') {
            if (codePreviewSecondary === 'html') {
                copyCode(htmlCode)
            }
            if (codePreviewSecondary === 'react') {
                copyCode(reactCode)
            }
            if (codePreviewSecondary === 'fluent') {
                copyCode(fluentCode)
            }
           
            if (codePreviewSecondary === 'css') {
                copyCode(cssCode)
            }
            if (codePreviewSecondary === 'json') {
                copyCode(JSON.stringify(lottieData))
            }
        }
    }

    const copyCode = (code) => {
        // console.log(code)
        navigator.clipboard.writeText(code)
            .then(() => {

                console.log('Code copied to clipboard successfully.');
            })
            .catch((error) => {
                console.error('Failed to copy code to clipboard:', error);
            });
    }

    return (

        <div>
            <div style={{ display: !fluentCode ? 'block' : 'none' }}>
                <SpecLoader />
            </div>
            <div>
                <Header switchLayout={handleLayoutChange} view_mode={props.view_mode} fluentCode={fluentCode} cssCode={cssCode} animationData={lottieData} lottiePlayerData={lottiePlayerData} spec_id={specID} notes={notes} css={cssCode} react={reactCode} html={htmlCode} fluent={fluentCode} lottiePreviewBG={lottiePreviewBG}></Header>
                <div id="spec-main-container" >
                    {lottieData ? (
                        <React.Fragment>
                            <div id="preview-container" style={{}}>
                                <div id="lottie-preview-color-picker" onClick={() => { setOpenColorPicker(!openColorPicker) }} style={{ height: openColorPicker ? '310px' : '26px' }}>
                                    {!openColorPicker ?
                                        <img src={ColorPickerIcon} alt="color-picker-icon" style={{ width: '18px', height: '18px' }} /> :
                                        <div style={{ display: 'grid', placeContent: 'center', padding: '2px ' }}>
                                            <div style={{ display: 'grid', placeContent: 'center', height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#555555', marginBottom: '6px' }}>
                                                <img src={ColorPickerIcon} alt="color-picker-icon" style={{ width: '14px', height: '14px', }} />
                                            </div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: 'white', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("transparent") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#388BFD', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#388BFD") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#A371F7', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#A371F7") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#DB61A2', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#DB61A2") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#F85149', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#F85149") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#DB6D28', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#DB6D28") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#BB800A', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#BB800A") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#2EA043', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#2EA043") }}></div>
                                            <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#555555', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#555555") }}></div>
                                        </div>
                                    }
                                </div>
                                <div className="magnifyControl">
                                    <div onClick={() => { zoomAnimation(.2) }}>
                                        <img style={{ width: '16px', height: '16px' }} src={magnifyIcon} alt="magnify"></img>
                                    </div>
                                    <div onClick={() => { zoomAnimation(-.2) }}>
                                        <img style={{ width: '16px', height: '16px' }} src={demagnifyIcon} alt="magnify"></img>
                                    </div>
                                </div>

                                <div id="lottie-preview" ref={animationContainer} style={{ transform: `scale(${zoom})`, backgroundColor: lottiePreviewBG }}>
                                </div>
                            </div>

                            <div id="code-preview">
                                <div style={{ position: 'relative', width: codeSplit ? '50%' : '100%', height: 'inherit', borderRight: "1px solid #333333", overflow: 'auto', transition: 'all .3s cubic-bezier(0,0,0,1)' }}>
                                    <CodeHeader bucket="main" handleCodePreviewChange={handleCodePreviewChange} copyCodeToClipboard={copyCodeToClipboard} selected={codePreviewMain} handleCodeSplitChange={handleCodeSplitChange}></CodeHeader>
                                    {codePreviewMain === 'fluent' && !playerLoaded ?
                                        <div className="loading-container">
                                            <div>Generating Animation Code</div>
                                            <div className="dots">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </div> : null
                                    }
                                    {codePreviewMain === 'fluent' && playerLoaded && document.getElementById("lottie-preview").hasChildNodes() ?
                                        <LottieToFluent animationData={lottieData} updateFluentCode={updateFluentCode} updateCSSCode={updateCSSCode} lottiePreviewBG={lottiePreviewBG}></LottieToFluent>
                                        : null
                                    }
                                   {codePreviewMain === "css" && playerLoaded && document.getElementById("lottie-preview").hasChildNodes() ?
                                        <LottieToCSS cssCode={cssCode} animationData={lottieData}></LottieToCSS>
                                        : null
                                    }

                                    {codePreviewMain === "json" ?
                                        <LottieToPrettyJSON animationData={lottieData}></LottieToPrettyJSON>
                                        : null
                                    }
                                </div>
                                {codeSplit ?
                                    <div style={{ width: '50%', overflow: 'auto' }}>
                                        <CodeHeader bucket="secondary" handleCodePreviewChange={handleCodePreviewChange} selected={codePreviewSecondary} copyCodeToClipboard={copyCodeToClipboard} handleCodeSplitChange={handleCodeSplitChange}></CodeHeader>

                                        {codePreviewSecondary === 'fluent' ?
                                            <LottieToFluent animationData={lottieData} updateFluentCode={updateFluentCode} updateCSSCode={updateCSSCode} lottiePreviewBG={lottiePreviewBG}></LottieToFluent>
                                            : null
                                        }
                                        {codePreviewSecondary === 'css' && playerLoaded && document.getElementById("lottie-preview").hasChildNodes() ?
                                            <LottieToCSS animationData={lottieData} cssCode={cssCode}></LottieToCSS>
                                            : null
                                        }
                                        {codePreviewSecondary === "json" ?
                                            <LottieToPrettyJSON animationData={lottieData}></LottieToPrettyJSON>
                                            : null
                                        }
                                    </div> : null
                                }

                            </div>

                            <div id="bottom-container">
                                <div style={{ position: 'absolute', right: '46px', top: '54px' }} onClick={() => { toggleLoop() }}>
                                    {loop ?
                                        <img src={LoopOn} style={{ opacity: .8 }} alt="loopOff"></img> : <img src={LoopOff} alt="loopOff"></img>
                                    }
                                </div>

                                <div className="playControl-wrapper" style={{ display: "grid", gridTemplateColumns: "96px 1fr", marginBottom: "0px", marginTop: '0px' }}>
                                    <div style={{ display: "flex" }} onClick={() => { togglePlay() }}>
                                        {isPlaying ? (
                                            <img src={PauseIcon} alt="pauseIcon" />
                                        ) : (
                                            <img src={PlayIcon} alt="playIcon" />
                                        )}
                                        <div ref={timeDisplayRef} style={{ color: "white", height: "100%", marginLeft: "4px", marginTop: "16px" }}>
                                            0ms 
                                        </div>

                                    </div>
                                    <input
                                        id="playhead"
                                        type="range"
                                        min={0}
                                        max={animDuration}
                                        step={0.006}
                                        ref={playheadRef} // Attach the ref here instead of using `value`
                                        onChange={() => {
                                            if (playheadRef.current && animation.current) {
                                                animation.current.goToAndStop(playheadRef.current.value * animation.current.frameRate, true);
                                            }
                                        }}
                                    />
                                </div>

                                {fluentCode ?
                                    <div id="timeline-container" >
                                        <AnimationTimeline
                                            animationData={lottieData}
                                            cssCode={cssCode}
                                            toggleSoloLayer={toggleSoloLayer}
                                            frameRate={frameRate}
                                            totalFrames={totalFrames}
                                            fluentCode={fluentCode}
                                        ></AnimationTimeline>
                                    </div> : null
                                }
                            </div>

                        </React.Fragment>
                    ) : null
                    }

                </div>
            </div>

        </div>

    );
};

export default Spec;


