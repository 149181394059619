import React, { useState } from 'react';
import './CodeHeader.css'
import copyIcon from '../../Images/Icons/copy.svg';
import split_on from '../../Images/Icons/split_on.svg';
import split_off from '../../Images/Icons/split_off.svg'

const CodeHeader = (props) => {
    const [lineWidth, setLineWidth] = useState('50px');
    const [split, setSplit] = useState(true);
    const [copied, setCopied] = useState(false);
    const [selectedWidth, setSelectedWidth] = useState({
        html: '40px',
        react: '50px',
        fluent: '48px',
        angular: '74px',
        flutter: '68px',
        json: '40px',
        css: '34px',
    })
    const [selectedPos, setSelectedPos] = useState({
        fluent: '2px',
        html: '60px',
        css: '52px',
        flutter: '190px',
        json: '88px',

    })

    const handleCopy = () => {
        setCopied(true)
        // function to be executed after a delay
        function delayedFunction() {
            setCopied(false)
        }

        // call the delayed function after 2000 milliseconds (2 seconds)
        setTimeout(delayedFunction, 2000);

    }

    const handleViewChange = () => {
        props.handleCodeSplitChange(!split)
        setSplit(!split)
    }
    return (
        <div style={{ display: 'relative' }}>
            <div style={{ display: 'flex', color: 'white', cursor: 'pointer', fontFamily: 'Orbitron', fontSize: '10px', fontWeight: '800', paddingTop: '8px', alignContent: 'center', gap: '10px', paddingLeft: '10px', position: 'relative', opacity: .6 }}>
                <div onClick={() => { props.handleCodePreviewChange('fluent', props.bucket) }}>REACT</div>  
                {/* <div onClick={() => { props.handleCodePreviewChange('html', props.bucket) }}>HTML</div> */}
                {/* <div onClick={() => { props.handleCodePreviewChange('react', props.bucket) }}>REACT</div> */}
                {/* <div onClick={() => { props.handleCodePreviewChange('angular') }}>ANGULAR</div> */}
                {/* <div onClick={() => { props.handleCodePreviewChange('flutter') }}>FLUTTER</div> */}
                <div onClick={() => { props.handleCodePreviewChange('css', props.bucket) }}>CSS</div>
                <div onClick={() => { props.handleCodePreviewChange('json', props.bucket) }}>JSON</div>
                <div className='selectionLine' style={{ width: selectedWidth[props.selected], height: '2px', transform: `translateX(${selectedPos[props.selected]})` }}></div>
                <div style={{ position: 'absolute', display: 'flex', top: '4px', right: '14px', opacity: .6 }}>
                    {props.bucket === "main" ?
                        split ?
                            <div onClick={handleViewChange}>
                                <img src={split_on} style={{ height: '24px', marginRight: '10px' }} alt="split icon" ></img>
                            </div> :
                            <div onClick={handleViewChange}>
                                <img src={split_off} style={{ height: '24px', marginRight: '10px' }} alt="split icon" ></img>
                            </div>
                        : null
                    }
                    {!copied ?
                        <img src={copyIcon} onClick={() => {props.copyCodeToClipboard(props.bucket); handleCopy() }} style={{ height: '24px' }} alt="css icon" ></img> : <div style={{fontFamily: "DM sans", color:'white', display:'flex', marginTop:'6px'}}>Copied!</div>
                    }

                </div>
            </div>
        </div>
    );
};

export default CodeHeader;


