import React from 'react';
import './InternalHomeHeader.css';
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import ddIcon from '../Images/Icons/dd.svg';
import upgradeIcon from '../Images/Icons/upgrade.svg'
import { signOut } from "firebase/auth";


function InternalHomeHeader(props) {
  //console.log(props)
  const logout = () => {
    signOut(auth).then(() => {
      console.log("user logged out")
      window.location = "/"
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
  } 

  return (
    <header className="header">
      <div className="logo" onClick={() => { window.location.href='/' }}>MotionSpec</div>
      <nav className="nav">
        <a style={{ color: 'white' }} href="/docs">Docs</a>
        {/* {!props?.subActive ?
          <div className="primary-btn" style={{ display: 'flex', placeContent: 'center', marginRight: '16px', marginLeft: '14px' }}>
            <img src={upgradeIcon} style={{ height: '22px', marginTop: '4px' }} alt="dd-icon"></img>
            <div style={{ marginRight: '10px', marginLeft: '4px', marginTop: '6px', fontSize: '14px' }} onClick={() => { window.location.href = process.env.REACT_APP_STRIPE_BILLING_LINK + "?prefilled_email=" + props.userData.email }}>Upgrade</div>
          </div>:null
         } */}

        <div className="dropdown">
          <div style={{ display: 'flex' }}>
            <img src={ddIcon} style={{ height: '22px', marginTop: '4px' }} alt="dd-icon"></img>
            {props.userData.profile_pic==="initials" ? 
              <div className="account-btn">{props.userData.first_name.charAt(0) + props.userData.last_name.charAt(0)}</div>:
              <img src={props.userData.profile_pic} style={{height:'30px', width:'30px', borderRadius:'3000px', overflow:'hidden'}} alt={props.userData.profile_pic}></img>
            }
            
          </div>
          <div className="dropdown-content">
          <a className="dd-content" href={"/profile/"+props.userData.uid}>Profile</a>
            <a className="dd-content" href="/teams">Invite Team</a>
            {props.subActive ? 
              <a className="dd-content" href={process.env.REACT_APP_STRIPE_BILLING_LINK + "?prefilled_email=" + props.userData.email}>Subscription</a>:null
            }
            <a className="dd-content" href="#">Settings</a>
            <a className="dd-content" onClick={logout}>Logout</a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default InternalHomeHeader;
