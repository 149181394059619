import React, { useState, useEffect } from 'react';
import './PasswordProtectedPage.css';
import lockIcon from '../Images/Icons/lock.svg';
import Spec from '../Spec';
import SpecLoader from '../Loaders/SpecLoader';
const PasswordProtectedPage = () => {
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPublic, setIsPublic] = useState(false)
    const [lottieData, setLottieData] = useState(null);
    const [notes, setNotes] = useState("");
    const [attachments, setAttachments] = useState(null);
    const [specID, setSpecID] = useState(null);
    const [linkID, setLinkID] = useState(null);
    const [subText, setSubText] = useState("Validating Link...")
    useEffect(() => {
        const path = window.location.pathname; // Gets the path of the current page
        const link_id = path.split('/').pop();
        setLinkID(link_id)
        verifyLinkSharingPermissions(link_id)
    }, []);

    const verifyLinkSharingPermissions = (link_id) => {
        setIsLoading(true)
        fetch('https://us-central1-motionspec-709ac.cloudfunctions.net/linkVerification?id=' + encodeURIComponent(link_id))
            .then(response => response.json())
            .then(result => {
                if (result.type === "public") {
                    //console.log('Link is Public');
                    getSpecData(result.spec_id)
                    setIsPublic(true)
                    

                } else {
                 
                    // Password is invalid
                    //console.log('Link is Private');
                    setTimeout(delayLoad, 400);
                    setIsPublic(false)
                    
                   
                }
            })
            .catch(error => {
                // Handle errors here
                console.error(error);
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const path = window.location.pathname; // Gets the path of the current page
        const link_id = path.split('/').pop();
        fetch('https://us-central1-motionspec-709ac.cloudfunctions.net/pswVerification?id=' + encodeURIComponent(link_id) + '&password=' + encodeURIComponent(password))
            .then(response => response.json())
            .then(result => {

                if (result.access_granted) {
                    // Password is valid
                    //console.log('Password is valid');
                    getSpecData(result.spec_id)
                    setSubText("Opening Spec...")

                } else {
                    setShowError(true)
                    setIsLoading(false)
                    // Password is invalid
                    //console.log('Password is invalid');

                }
            })
            .catch(error => {
                // Handle errors here
                console.error(error);
            });
    };


    const getSpecData = async (spec_id) => {
        fetch('https://us-central1-motionspec-709ac.cloudfunctions.net/getSpecData?spec_id=' + encodeURIComponent(spec_id))
            .then(response => response.json())
            .then(result => {
                
                setAttachments(result.attachments)
                setNotes(result.notes)
                setSpecID(result.spec_id)
                setTimeout(()=>{delayLoad(result.lottieData)}, 380);
           
            })
            .catch(error => {
                // Handle errors here
                console.error(error);
            });
    };

    const delayLoad=(lottieData)=>{
        setLottieData(lottieData)
        setIsLoading(false)
    }


    if (isLoading) {
        return (
            <div style={{ width: '100vw', height: '100vh', display: 'grid', placeContent: 'center' }}>
                <div>
                    <SpecLoader subText={subText}/>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {lottieData && specID ?
                <Spec lottieData={lottieData} attachments={attachments} notes={notes} spec_id={specID} view_mode="dev"></Spec> :
                <div className="password-protected-page">
                    <div className="password-protected-form-wrapper">
                        <div id="pass-h1" style={{ color: 'white', textAlign: 'center' }}>MotionSpec</div>
                        <form onSubmit={handleSubmit} id="pass-form">
                            <img src={lockIcon} style={{ width: '40px' }}></img>
                            <div id="pass-form-subheading">Password Protected</div>
                            <div id="pass-form-p">This motion spec is password protected. Please enter a valid password to gain access.</div>
                            <input type="password" id="pass-input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            {showError && <p className="error-message">Incorrect password. Please try again.</p>}
                            {isLoading ? <button id="pass-sub-button" disabled={true} type="submit">{subText}</button> :
                                <button id="pass-sub-button" type="submit">Submit</button>
                            }
                        </form>
                        <div style={{ height: '50px' }}></div>
                    </div>
                </div>
            }
        </React.Fragment>

    );
};

export default PasswordProtectedPage;
