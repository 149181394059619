import React, { useState, useEffect, useCallback } from 'react';
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import { getFirestore, collection, addDoc, getDoc, doc, query, getDocs, where } from "firebase/firestore";
import { Navigate } from "react-router-dom";
import Collection from '../Collection/Collection';
import InternalHomeHeader from './InternalHomeHeader';


const InternalHome = (props) => {
    const [currentView, setCurrentView] = useState("home");
    const [userData, setUserData] = useState(null);
    const [subActive, setSubActive] = useState(false);
    const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);
    const [msg, setMessage]= useState(null)
    useEffect(() => {
        if (auth.currentUser.uid) {
            fetchUserData(auth.currentUser.uid);
        }
    }, [])

    const updateCurrentView = (view) => {
        setCurrentView(view)
    }



    const fetchUserData = async (uid) => {
        const q = query(collection(db, "users"), where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        let data = []
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });
        setUserData(data[0])

    };

    return (
        <div style={{ backgroundColor: 'white', height: '100vh', }}>
            {userData ?
                <React.Fragment>
                    <InternalHomeHeader userData={userData}  subActive={subActive}/>
                    {currentView === "home" ?
                        <div style={{ padding: '30px', backgroundColor: 'var(--dark-002)', height: '100vh', overflow: 'auto' }}>
                            <Collection userData={userData} />
                            <div style={{height:'200px'
                            }}></div>
                        </div> : null
                    }

                </React.Fragment> : null
            }

        </div>
    );
};

export default InternalHome;
