import './App.css';
import Spec from './Spec';
import Login from './Auth/login';
import SignUp from './Auth/signup';
import InternalHome from './InternalHome/InternalHome';
import Upgrade from './Upgrade/Upgrade';
import ProfilePage from './ProfilePage/ProfilePage';
import SupportTable from './Docs/Suppored_Features/SupportedFeatures';
import { GettingStarted} from './Docs/Getting_Started/GettingStarted';
import { HelpDocumentation } from './Docs/HelpDocs';
import PasswordProtectedPage from './PasswordProtected/PasswordProtected';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './ProtechedRoutes/ProtectedRoutes'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route element={<ProtectedRoutes />}>
            <Route exact path="/home" element={<InternalHome />} />
            <Route path="folders/*" element={<InternalHome />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/spec/:id" element={<Spec view_mode="user"/>} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/supportedfeattues/" element={<SupportTable/>} />
          </Route>
          <Route element={<HelpDocumentation />}>
            <Route path="/docs/" element={<SupportTable/>} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/gettingstarted/" element={<GettingStarted/>} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/profile/:id" element={<ProfilePage />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/upgrade/" element={<Upgrade />} />
          </Route>
          
          <Route exact path="/dev/:id" element={<PasswordProtectedPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
 