import React, { useState, useEffect, useRef } from "react";
import keyframeIcon from "../Images/Icons/keyframe.svg";
import { extractMotionData } from "./extractMotionData";
import "./LayerComponent.css";

const ShapeComponent = ({ shape, layer, fps, totalFrames, fluentCode, formatColor, calculateDelta, searchForUsageInRender, searchForMotionDef }) => {
    const [activeTooltipId, setActiveTooltipId] = useState(null);
    const [motionData, setMotionData] = useState(null);
    const componentRef = useRef(null);

    const propertyColors = {
        position: "#3A96DD",
        scale: "#1CB86D",
        rotation: "#da9c38",
        opacity: "#DA3633",
        anchor: "#F2CC60",
        size: "#4028db",
        fill: "#888888",
        offsetPath: "#ba3add",
        visibility: 'red',
        "Curved Path": "#ba3add",
    };

    useEffect(() => {
        if (fluentCode && shape.nm) {

            let hasAnimation = false
            //check if animated
            if (shape.it) {
                
                shape.it.forEach((param) => {

                    if (param.a && param.a === 1) {
                        hasAnimation = true
                    }
                    const extractedData = extractMotionData(fluentCode, layer.ln + "_" + shape.ln + "_" + shape.ix);
                    setMotionData(extractedData);
                })


            }



            // console.log("extractedData",extractedData)

        }
    }, [fluentCode, shape.nm]);

    const handleTooltipToggle = (id) => {
        setActiveTooltipId((prevId) => (prevId === id ? null : id));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {

            if (
                componentRef.current && // Ensure component exists

                !event.target.closest(".keyframe-segment") &&
                !event.target.closest(".keyframe") && /// Not a keyframe
                !event.target.closest(".tooltip")// Not inside a tooltip
                // Not inside timeline
            ) {
                setActiveTooltipId(null); // Hide tooltips
                
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const formatNumber = (num) => {
        if (num === null || num === undefined) return null;
        const rounded = Math.round(num * 100) / 100;
        return Number.isInteger(rounded) ? rounded.toString() : rounded.toFixed(2);
    };

    const formatDelta = (start, end, isPx) => {
        if (start === null || end === null) return null;
        const delta = end - start;
        return `${delta >= 0 ? "+" : ""}${formatNumber(delta)}${isPx ? "px" : ""}`;
    };

    const formatValue = (value, propKey) => {
        if (value === null || value === undefined) return null;
        if (propKey === "position" || propKey === "size") {
            let formattedValue = [];
            if (value.x !== undefined) formattedValue.push(`x: ${formatNumber(value.x)}px`);
            if (value.y !== undefined) formattedValue.push(`y: ${formatNumber(value.y)}px`);
            if (value.width !== undefined) formattedValue.push(`width: ${formatNumber(value.width)}px`);
            if (value.height !== undefined) formattedValue.push(`height: ${formatNumber(value.height)}px`);
            return formattedValue.join(", ");
        } else if (propKey === "scale") {
            return `scaleX: ${formatNumber(value.scaleX)}, scaleY: ${formatNumber(value.scaleY)}`;
        } else if (propKey === "rotation") {
            return `angle: ${formatNumber(value.angle)}deg`;
        } else {
            return formatNumber(value);
        }
    };

    const renderKeyframes = (keyframesData, propKey, idx) => {
        // console.log("keyfreamsdata", keyframesData)
        if (!keyframesData || !Array.isArray(keyframesData)) return null;
        const hasKeyframeAtStart = keyframesData.some((keyframe) => keyframe.offset === 0);
        let gradientStops = keyframesData.map((keyframe) => {
            const percentage = keyframe.offset * 100;
            const color = formatColor(keyframe.fill) || "rgba(255,255,255,1)";
            return `${color} ${percentage}%`;
        });
        if (propKey === "fill" && keyframesData.length > 0) {
            const lastKeyframe = keyframesData[keyframesData.length - 1];
            const lastColor = formatColor(lastKeyframe.fill) || "rgba(255,255,255,1)";
            gradientStops.push(`${lastColor} 100%`);
        }
        return (
            <div className="timeline-anim-container" ref={componentRef} key={idx}>

                <div className="layer-parameter">{propKey.charAt(0).toUpperCase() + propKey.slice(1)}</div>
                <div className="keyframe-line-container-shape" key={idx}>
                    <div
                        className="keyframeline"
                        style={{
                            position: "relative",
                            background: "#333",
                            marginLeft: `${(layer.ip / totalFrames) * 100}%`,
                            // marginRight: `${(layer.op / totalFrames) * 100}%`,
                            //width: `${((layer.op - layer.ip) / totalFrames) * 100}%`,
                        }}
                    >
                        {keyframesData.map((keyframe, idx) => {
                            const nextKeyframe = keyframesData[idx + 1];
                            const keyframeId = `kf-${layer.nm}-${propKey}-${idx}`;
                            const segmentId = `trans-${keyframeId}`;

                            return (
                                <React.Fragment>
                                    {/* Keyframe Icon with Clickable Tooltip */}
                                    <div
                                        className="keyframe"
                                        key={keyframeId}
                                        style={{
                                            position: "absolute",
                                            left: `${keyframe.offset * 100}%`,
                                            transform: "translate(-50%, -50%)",
                                        }}
                                        onClick={() => handleTooltipToggle(keyframeId)}
                                    >
                                        <img className="keyframeIcon" src={keyframeIcon} alt="keyframe" />

                                        {/* Keyframe Tooltip */}
                                        {activeTooltipId === keyframeId && (
                                            <div className="tooltip">
                                                <div><strong>{propKey.charAt(0).toUpperCase() + propKey.slice(1)} Keyframe</strong></div>

                                                {/* Display relevant value based on property type */}
                                                {propKey === "position" && keyframe.value && (
                                                    <div><strong>Value:</strong> x: {formatNumber(keyframe.value.x)}px, y: {formatNumber(keyframe.value.y)}px</div>
                                                )}
                                                {propKey === "scale" && keyframe.value && (
                                                    <div><strong>Value:</strong> scaleX: {formatNumber(keyframe.value.scaleX)}; scaleY: {formatNumber(keyframe.value.scaleY)}</div>
                                                )}
                                                {propKey === "rotation" && keyframe.value && (
                                                    <div><strong>Value:</strong> {formatNumber(keyframe.value.angle)}deg</div>
                                                )}
                                                {propKey === "opacity" && keyframe.value && (
                                                    <div><strong>Value:</strong> {formatNumber(keyframe.value.opacity)}</div>
                                                )}
                                                {propKey === "visibility" && keyframe.value && (
                                                    <div><strong>Value:</strong> {formatNumber(keyframe.value.visibility)}</div>
                                                )}
                                                {propKey === "offsetPath" && keyframe.value && (
                                                    <div><strong>Value:</strong> Curved Motion Path</div>
                                                )}
                                                {propKey === "size" && keyframe.value && (
                                                    <div><strong>Value:</strong> width: {formatNumber(keyframe.value.width)}px; height: {formatNumber(keyframe.value.height)}px</div>
                                                )}
                                                {propKey === "fill" && keyframe.fill && (
                                                    <div><strong>Value:</strong> {formatColor(keyframe.fill)}</div>
                                                )}

                                                <div><strong>Time:</strong> {formatNumber(keyframe.time)}ms</div>
                                                <div><strong>Offset:</strong> {formatNumber(keyframe.offset * 100)}%</div>
                                                {/* OffsetPath Details */}
                                                {propKey === "Curved Path" && keyframe.value && (
                                                    <>
                                                        <div onClick={() => {

                                                        }}><strong>Path: </strong>View In Code</div>
                                                        {keyframe.value.distance !== null && (
                                                            <div><strong>Offset Distance:</strong> {formatNumber(keyframe.value.distance)}%</div>
                                                        )}
                                                    </>
                                                )}

                                                {/* Fill Color Display */}
                                                {keyframe.fill && <div><strong>Color:</strong> {formatColor(keyframe.fill)}</div>}

                                                <div className="tooltip-nubbin"></div>
                                            </div>
                                        )}

                                    </div>

                                    {/* In-Between Transition Tooltip (Compact Layout) */}
                                    {nextKeyframe && (
                                        <div>
                                            {activeTooltipId === segmentId && (
                                                <div
                                                    className="tooltip-inbetween"
                                                    style={{
                                                        left: `${(keyframe.offset * 100) + ((nextKeyframe.offset - keyframe.offset) * 50)}%`,
                                                    }}
                                                >
                                                    <div><strong>Animation Interpolation</strong></div>
                                                    {keyframe.easing && <div><strong>Easing:</strong> {keyframe.easing}</div>}
                                                    <div><strong>Duration:</strong> {formatNumber(nextKeyframe.time - keyframe.time)}ms</div>

                                                    {/* Fill Interpolation */}
                                                    {propKey === "fill" ? (
                                                        <>
                                                            <div><strong>Start Color:</strong> {formatColor(keyframe.fill)}</div>
                                                            <div><strong>End Color:</strong> {formatColor(nextKeyframe.fill)}</div>
                                                        </>
                                                    ) : (
                                                        propKey !== "Curved Path"  && propKey !== "opacity" ? (
                                                            <>
                                                                <div><strong>Start Key:</strong> {formatValue(keyframe.value, propKey)}</div>
                                                                <div><strong>End Key:</strong> {formatValue(nextKeyframe.value, propKey)}</div>
                                                                <div><strong>Delta:</strong> {calculateDelta(keyframe.value, nextKeyframe.value, propKey)}</div>
                                                            </>
                                                        ) : null

                                                    )}

                                                    {/* Offset Path Transition */}
                                                    {propKey === "Curved Path" && (
                                                        <>
                                                            {keyframe.value?.distance !== null && nextKeyframe.value?.distance !== null && (
                                                                <div>
                                                                    <div><strong>Start Key:</strong> {formatValue(keyframe.value.distance, propKey)}</div>
                                                                    <div><strong>End Key:</strong> {formatValue(nextKeyframe.value.distance, propKey)}</div>
                                                                    <div><strong>Offset Distance Delta:</strong> {formatDelta(keyframe.value.distance, nextKeyframe.value.distance, true)}</div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}

                                                    {/* Opacity Transition */}
                                                    {propKey === "opacity" && (
                                                        <>
                                                            <div><strong>Start Opacity:</strong> {formatNumber(keyframe.value?.opacity)}</div>
                                                            <div><strong>End Opacity:</strong> {formatNumber(nextKeyframe.value?.opacity)}</div>
                                                        </>
                                                    )}

                                                    <div className="tooltip-nubbin"></div>
                                                </div>
                                            )}
                                            <div
                                                className={`keyframe-segment ${activeTooltipId === segmentId ? "glowing" : ""}`}
                                                style={{
                                                    position: "absolute",
                                                    left: `${keyframe.offset * 100}%`,
                                                    width: `${(nextKeyframe.offset - keyframe.offset) * 100}%`,
                                                    height: "100%",
                                                    cursor: "pointer",
                                                    background: propKey === "fill" ? `linear-gradient(to right, ${keyframe.fill}, ${nextKeyframe.fill})` : propKey === "opacity" ? `linear-gradient(to right, rgba(230, 60, 60,${keyframe.value.opacity}), rgba(230, 60, 60,${nextKeyframe.value.opacity}))`: propertyColors[propKey],
                                                }}
                                                onClick={() => handleTooltipToggle(segmentId)}
                                            ></div>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {motionData ?
                <div className="timeline-layer">
                    <div style={{ position: 'absolute', right: '38px', display: 'flex', gap: '10px' }}>
                        <button className="scroll-btn-code-search"onClick={() => searchForMotionDef(layer.ln + "_" + shape.ln + "_" + shape.ix)}>Motion Component</button>
                        <button className="scroll-btn-code-search" onClick={() => searchForUsageInRender(layer.ln + "_" + shape.ln + "_" + shape.ix)}>Motion Usage</button>
                    </div>
                    <div className="timeline-layer-name">{layer.ln + "_" + shape.ln + "_" + shape.ix + " " + "- (Nested Shape)"}</div>
                    {motionData && motionData.map((animData, idx) => renderKeyframes(animData.keyframes, animData.keyframes[0]?.property || `unknown-${idx}`, idx))}
                </div> : null

            }
        </React.Fragment>
    );
};

export default ShapeComponent;
