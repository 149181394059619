import React, { useState, useEffect } from "react";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.css';
import OpenStackBlitzButton from '../../CodeSandbox/OpenInStackBlitz';
import lottieToCSS from "./lottieToCSS";
import convertLottieToFluent from "../Fluent/convertLottieToFluent";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { format } from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';
import parserCSS from "prettier/parser-postcss";

const formatReactCode = (code) => {
  return format(code, {
    parser: 'babel',
    plugins: [parserBabel],
    semi: true,
    singleQuote: true,
    jsxSingleQuote: true,
    trailingComma: 'all',
    arrowParens: 'always',
    bracketSpacing: true,
    jsxBracketSameLine: false,
    printWidth: 80,
    tabWidth: 2,
  });
}


const formattedCSS = (code) => {
  return format(code, {
    parser: "css",
    plugins: [parserCSS],
    singleQuote: true,
    tabWidth: 2,
  });
}


export default function LottieToReact(props) {
  const [reactCode, setReactCode] = useState('');
  const [cssCode, setCSSCode] = useState('');
  const [lottieData, setLottieData] = useState('')

  useEffect(() => {

    if (Object.keys(props.animationData).length > 1) {
      setLottieData(props.animationData)

      // console.log("animDataOut", props.animationData)
      const react = convertLottieToFluent(props.animationData, props.lottiePreviewBG)
      const formattedReact = formatReactCode(react);
      setReactCode(formattedReact)
      props.updateFluentCode(formattedReact)
      const css = lottieToCSS(props.animationData)
      let formattedCSSCode = formattedCSS(css)

      setCSSCode(formattedCSSCode)
      props.updateCSSCode(formattedCSSCode)
      // console.log(cssCombined)
      // setReactCode(react)
      // props.updateFluentCode(react)
    }

  }, [props.animationData, props.lottiePreviewBG])

  return (
    <div style={{ position: 'relative', backgroundColor: 'rgb(30, 30, 30)' }} >
      {/* <div style={{position:'fixed', zIndex:100, bottom: '30px', right: '30px'}}>
        <OpenStackBlitzButton code={reactCode} cssCode={cssCode} lottieData={props.animationData} />
      </div> */}
      <SyntaxHighlighter language="javascript" style={vscDarkPlus} id="scrollabocecode">
        {reactCode}
      </SyntaxHighlighter>
    </div>
  );
}







