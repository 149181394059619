// import React from 'react';
// import PropTypes from 'prop-types';
// import sdk from '@stackblitz/sdk';
// import stackblitz from '../Images/Icons/stackblitz.png';
// import '../Header/Header.css';

// const OpenStackBlitzButton = ({ code, lottieData, cssCode }) => {
//     const generateImagesJS = (lottieData) => {
//         const assets = lottieData.assets || [];
//         const imports = assets.map((asset, index) => {
//             const base64Data = asset.p; // Assuming asset.p contains the base64 data
//             return `export const image_${index} = "${base64Data}";\n`;
//         }).join('');
//         return imports;
//     };

//     const openStackBlitzWithCode = () => {
//         // Generate the images.js file content
//         const imagesJSContent = generateImagesJS(lottieData);

//         // Project configuration
//         const project = {
//             title: 'Fluent UI React Project',
//             description: 'A React project using Fluent UI components',
//             template: 'create-react-app',
//             dependencies: {
//                 '@fluentui/react-components': '9.54.2',
//                 '@types/react': '18.3.3',
//                 '@types/react-dom': '18.3.0',
//                 react: '18.3.1',
//                 'react-dom': '18.3.1',
//                 'react-scripts': '4.0.3',
//                 typescript: 'latest',
//             },
//             files: {
//                 'public/index.html': '<div id="root"></div>',
//                 'src/index.tsx': `
// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import App from './App';

// const container = document.getElementById('root');
// const root = createRoot(container!);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
//                 `,
//                 'src/App.tsx': `
// import { FluentProvider, webLightTheme } from '@fluentui/react-components';
// import { CreateMotionComponentDefault as Example } from './example';

// const App = () => {
//   return (
//     <FluentProvider theme={webLightTheme}>
//       <Example />
//     </FluentProvider>
//   );
// };

// export default App;
//                 `,
//                 'src/example.tsx': code,
//                 'src/styles.css': cssCode,
//                 'src/assets/images.js': imagesJSContent, // Add the generated images.js file
//                 'tsconfig.json': JSON.stringify({
//                     compilerOptions: {
//                         target: "es5",
//                         lib: ["dom", "dom.iterable", "esnext"],
//                         allowJs: true,
//                         skipLibCheck: true,
//                         esModuleInterop: true,
//                         allowSyntheticDefaultImports: true,
//                         strict: true,
//                         forceConsistentCasingInFileNames: true,
//                         noFallthroughCasesInSwitch: true,
//                         module: "esnext",
//                         moduleResolution: "node",
//                         resolveJsonModule: true,
//                         isolatedModules: true,
//                         noEmit: true,
//                         jsx: "react-jsx"
//                     },
//                     include: ["src"]
//                 }, null, 2),
//                 'package.json': JSON.stringify({
//                     name: 'fluent-ui-react-project',
//                     version: '0.1.0',
//                     private: true,
//                     dependencies: {
//                         '@fluentui/react-components': '9.54.2',
//                         '@types/react': '18.3.3',
//                         '@types/react-dom': '18.3.0',
//                         react: '18.3.1',
//                         'react-dom': '18.3.1',
//                         'react-scripts': '4.0.3',
//                         typescript: 'latest',
//                     },
//                     scripts: {
//                         start: 'react-scripts start',
//                         build: 'react-scripts build',
//                         test: 'react-scripts test',
//                         eject: 'react-scripts eject',
//                     },
//                 }, null, 2),
//             },
//             settings: {
//                 installDependencies: true, // Ensure dependencies are installed
//             },
//             openFile: 'src/example.tsx' // Open Example.tsx by default
//         };

//         // Open the project in StackBlitz
//         sdk.openProject(project);
//     };

//     return (
//         <div>
//             <div className="header-btn-primary" style={{ backgroundColor: 'white' }} onClick={openStackBlitzWithCode}>
//                 <img className="header-icon" src={stackblitz} alt="shareIcon"></img>
//                 <div className="header-text" style={{ color: 'var(--primary-color)' }}>StackBlitz</div>
//             </div>
//         </div>
//     );
// };

// OpenStackBlitzButton.propTypes = {
//     code: PropTypes.string.isRequired,
//     lottieData: PropTypes.object.isRequired,
// };

// export default OpenStackBlitzButton;


import React from 'react';
import PropTypes from 'prop-types';
import sdk from '@stackblitz/sdk';
import stackblitz from '../Images/Icons/stackblitz.png';
import '../Header/Header.css';

const OpenStackBlitzButton = ({ code, lottieData, cssCode }) => {
  const generateImagesJS = (lottieData) => {
    const assets = lottieData.assets || [];
    return assets
      .map((asset, index) => {
        const base64Data = asset.p; // Assuming asset.p contains the base64 data
        return `export const image_${index} = "${base64Data}";\n`;
      })
      .join('');
  };

  const openStackBlitzWithCode = () => {
    const imagesJSContent = generateImagesJS(lottieData);

    const project = {
      title: 'Fluent UI React Project (Vite)',
      description: 'A Vite-based React project using Fluent UI components',
      template: 'node',
      files: {
        'index.html': `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Fluent UI + Vite</title>
  </head>
  <body>
    <div id="root"></div>
    <script type="module" src="/src/index.tsx"></script>
  </body>
</html>
        `,
        'vite.config.ts': `
import { defineConfig } from 'vite';
import react from '@vitejs/plugin-react';

export default defineConfig({
  plugins: [react()],
});
        `,
        'src/index.tsx': `
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
        `,
        'src/App.tsx': `
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { CreateMotionComponentDefault as Example } from './example';

const App = () => {
  return (
    <FluentProvider theme={webLightTheme}>
      <Example />
    </FluentProvider>
  );
};

export default App;
        `,
        'src/example.tsx': code,
        'src/styles.css': cssCode,
        'src/assets/images.js': imagesJSContent,
        'tsconfig.json': JSON.stringify(
          {
            compilerOptions: {
              target: 'ESNext',
              lib: ['DOM', 'DOM.Iterable', 'ESNext'],
              module: 'ESNext',
              moduleResolution: 'Node',
              strict: true,
              jsx: 'react-jsx',
              esModuleInterop: true,
              skipLibCheck: true,
              resolveJsonModule: true,
              isolatedModules: true,
              noEmit: true,
            },
            include: ['src'],
          },
          null,
          2
        ),
        'package.json': JSON.stringify(
          {
            name: 'fluent-ui-react-project',
            version: '0.1.0',
            private: true,
            dependencies: {
              '@fluentui/react-components': '9.54.2',
              react: '18.3.1',
              'react-dom': '18.3.1',
            },
            devDependencies: {
              typescript: '^5.3.3',
              vite: '^5.0.0',
              '@vitejs/plugin-react': '^4.0.0',
              '@types/react': '18.3.3',
              '@types/react-dom': '18.3.0',
            },
            scripts: {
              dev: 'vite',
              build: 'vite build',
              preview: 'vite preview',
            },
          },
          null,
          2
        ),
      },
     
      settings: {
        installDependencies: true,
      },
      

    
    };

    sdk.openProject(project, {
        newWindow: true,
        openFile: ['src/example.tsx'],
      });
  };

  return (
    <div>
      <div className="header-btn-primary" style={{ backgroundColor: 'white' }} onClick={openStackBlitzWithCode}>
        <img className="header-icon" src={stackblitz} alt="shareIcon" />
        <div className="header-text" style={{ color: 'var(--primary-color)' }}>StackBlitz</div>
      </div>
    </div>
  );
};

OpenStackBlitzButton.propTypes = {
  code: PropTypes.string.isRequired,
  lottieData: PropTypes.object.isRequired,
  cssCode: PropTypes.string,
};

export default OpenStackBlitzButton;
