let shapeMorph=[]
// export default function lottieToCSS(lottieJSON) {
//     const frameRate = lottieJSON.fr; // Frames per second
//     const totalFrames = lottieJSON.op - lottieJSON.ip; // Total animation frames
//     let cssAnimations = "img{width:100%;}\n\n";

//     window.localStorage.setItem("shapeMorph", [])
    
//     lottieJSON.layers.forEach(layer => {
       
//         // ✅ Process Shape, Solid, and Image Layers
//         if (![1, 2, 4].includes(layer.ty)) return; 
//         cssAnimations +=generateGradientCSS(layer, lottieJSON);
//         // ✅ Generate drop shadow animation for all layers
//         cssAnimations += getBoxShadowCSS(layer, frameRate, totalFrames, lottieJSON.ip);

//         // ✅ Process Shape Layer animations
//         if (layer.ty === 4 && layer.shapes) {
//             layer.shapes.forEach(shapeGroup => {
//                 if (shapeGroup.ty !== "gr") return; // Only process shape groups
                
//                 shapeGroup.it.forEach(shape => {
//                     if (shape.ty !== "sh" || !shape.ks.a) return; // Only process animated paths
                    
//                     let keyframes = "";
//                     let shapeName = shape.ln.replace(/[^a-zA-Z0-9]/g, "_"); // Safe CSS class name
//                     let lastPercent = 0;
//                     let lastPath = null;

//                     shape.ks.k.forEach((frame) => {
//                         const percent = Math.round(((frame.t - lottieJSON.ip) / totalFrames) * 100);
//                         const path = convertLottiePathToSVG(frame.s[0]); // Convert Lottie path data to SVG `d`

//                         if (path) {
//                             keyframes += `  ${percent}% { d: path("${path}"); }\n`;
//                             lastPercent = percent;
//                             lastPath = path;
//                         }
//                         let animObject={
//                             property: 'Shape Morph',
//                             layerName:layer.ln,
//                             offset: percent/100,
//                             easing: null,
//                             time: percent*(totalFrames/frameRate)*10,
//                             value: path,
//                             duration: totalFrames*frameRate
//                         }
//                         shapeMorph.push(animObject)
//                     });
                    
                
                    
//                     // Ensure the last keyframe is at 100%
//                     if (lastPercent !== 100 && lastPath) {
//                         keyframes += `  100% { d: path("${lastPath}"); }\n`;
//                     }

//                     if (keyframes) {
//                         cssAnimations += `
// @keyframes morph_${shapeName} {
// ${keyframes}}
// .${shapeName} {
//   animation: morph_${shapeName} ${totalFrames / frameRate}s infinite ease-in-out;
// }
// `;
//                     }
//                 });
//             });
//         }
//     });
//     window.localStorage.setItem("shapeMorph", JSON.stringify(shapeMorph))
//     return cssAnimations.trim(); // Return formatted CSS
// }



export default function lottieToCSS(lottieJSON) {
    const frameRate = lottieJSON.fr;
    const totalFrames = lottieJSON.op - lottieJSON.ip;
    let cssAnimations = "img{width:100%;}\n\n";
    let shapeMorph = [];
    
    if (!lottieJSON.layers) return cssAnimations.trim();

    lottieJSON.layers.forEach((layer, layerIndex) => {
        if (![1, 2, 4].includes(layer.ty)) return;
        
        cssAnimations += generateGradientCSS(layer, lottieJSON) || '';
        cssAnimations += getBoxShadowCSS(layer, frameRate, totalFrames, lottieJSON.ip) || '';

        if (layer.ty === 4 && layer.shapes) {
            layer.shapes.forEach((shapeGroup, groupIndex) => {
                if (shapeGroup.ty !== "gr") return;
                
                shapeGroup.it.forEach((shape, shapeIndex) => {
                    if (shape.ty !== "sh" || !shape.ks || !shape.ks.a || !Array.isArray(shape.ks.k)) return;
                    
                    let keyframes = "";
                    let shapeName = (shape.ln || `shape_${layerIndex}_${groupIndex}_${shapeIndex}`).replace(/[^a-zA-Z0-9]/g, "_");
                    let lastPercent = 0;
                    let lastPath = null;

                    const keyframesData = shape.ks.k;
                    for (let i = 0; i < keyframesData.length; i++) {
                        const frame = keyframesData[i];
                        const percent = Math.round(((frame.t - lottieJSON.ip) / totalFrames) * 100);
                        const pathData = frame.s?.[0];
                        const path = pathData ? convertLottiePathToSVG(pathData) : null;

                        if (path) {
                            keyframes += `  ${percent}% { d: path("${path}");`;
                            // Add easing for the transition TO the next keyframe
                            if (i < keyframesData.length - 1) {
                                const easing = calcMotionCurve(frame);
                                if (easing !== 'linear' && !isCloseToLinear(easing)) {
                                    keyframes += ` animation-timing-function: ${easing};`;
                                }
                            }
                            keyframes += ` }\n`;
                            lastPercent = percent;
                            lastPath = path;

                            shapeMorph.push({
                                property: 'Shape Morph',
                                layerName: layer.ln || "unnamed",
                                offset: percent / 100,
                                easing: calcMotionCurve(frame),
                                time: percent * (totalFrames / frameRate) * 10,
                                value: path,
                                duration: totalFrames * frameRate
                            });
                        }
                    }

                    if (lastPercent !== 100 && lastPath) {
                        keyframes += `  100% { d: path("${lastPath}"); }\n`;
                    }

                    if (keyframes) {
                        cssAnimations += `
@keyframes morph_${shapeName} {
${keyframes}}
.${shapeName} {
  animation: morph_${shapeName} ${totalFrames / frameRate}s infinite;
}
`;
                    }
                });
            });
        }
    });
    
    window.localStorage.setItem("shapeMorph", JSON.stringify(shapeMorph));
    return cssAnimations.trim();
}

function isCloseToLinear(easing, tolerance = 0.01) {
    if (easing === 'linear') return true;
    const match = easing.match(/cubic-bezier\(([^,]+),([^,]+),([^,]+),([^,]+)\)/);
    if (!match) return false;
    const [, x1, y1, x2, y2] = match.map(Number);
    return Math.abs(x1 - 0) < tolerance && 
           Math.abs(y1 - 0) < tolerance && 
           Math.abs(x2 - 1) < tolerance && 
           Math.abs(y2 - 1) < tolerance;
}
 


 
function convertLottiePathToSVG(shapeData) {
    if (!shapeData || !shapeData.v || !shapeData.i || !shapeData.o) return null;

    let path = `M${shapeData.v[0][0]},${shapeData.v[0][1]}`; // Start at the first vertex

    for (let i = 0; i < shapeData.v.length - (shapeData.c ? 0 : 1); i++) {
        const currentVertex = shapeData.v[i];
        const nextIndex = (i + 1) % shapeData.v.length; // Wraps around correctly

        const outCtrl = shapeData.o[i];   // Outgoing control point from current vertex
        const inCtrl = shapeData.i[nextIndex]; // ✅ FIX: Incoming control should be from `i+1` vertex
        const nextVertex = shapeData.v[nextIndex]; // Next vertex

        path += ` C${outCtrl[0] + currentVertex[0]},${outCtrl[1] + currentVertex[1]} ${inCtrl[0] + nextVertex[0]},${inCtrl[1] + nextVertex[1]} ${nextVertex[0]},${nextVertex[1]}`;
    }

    if (shapeData.c) path += " Z"; // Close the path properly

    return path;
}


function getBoxShadowCSS(layer, frameRate, totalFrames, startFrame) {
    let hasDropShadow = false;
    let animationKeyframes = [];
    let staticValues = {
        shadowColor: [0, 0, 0, 1], // Default Black
        shadowOpacity: 1,
        shadowDistance: 0,
        shadowSoftness: 0,
        shadowDirection: 0
    };

    let animationName = `shadowAnim_${layer.ind}`;
    let keyframes = "";
    let cssRules = "";

    if (layer.ef && layer.ef.length > 0) {
        layer.ef.forEach((effect) => {
            if (effect.ty === 25) { // Drop Shadow Effect
                hasDropShadow = true;
                if (effect.ef && effect.ef.length > 0) {
                    effect.ef.forEach((shadowParam) => {
                        const isAnimated = shadowParam.v.a;
                        const staticValue = !isAnimated ? shadowParam.v.k : null; // Use static value if not animated

                        switch (shadowParam.mn) {
                            case "ADBE Drop Shadow-0001": // Shadow Color
                                if (isAnimated) {
                                    animationKeyframes.push({ type: "color", frames: shadowParam.v.k });
                                } else {
                                    staticValues.shadowColor = staticValue;
                                }
                                break;
                            case "ADBE Drop Shadow-0002": // Opacity
                                if (isAnimated) {
                                    animationKeyframes.push({ type: "opacity", frames: shadowParam.v.k });
                                } else {
                                    staticValues.shadowOpacity = staticValue / 255; // Normalize 0-255 → 0-1
                                }
                                break;
                            case "ADBE Drop Shadow-0003": // Direction
                                if (isAnimated) {
                                    animationKeyframes.push({ type: "direction", frames: shadowParam.v.k });
                                } else {
                                    staticValues.shadowDirection = staticValue;
                                }
                                break;
                            case "ADBE Drop Shadow-0004": // Distance
                                if (isAnimated) {
                                    animationKeyframes.push({ type: "distance", frames: shadowParam.v.k });
                                } else {
                                    staticValues.shadowDistance = staticValue;
                                }
                                break;
                            case "ADBE Drop Shadow-0005": // Softness (Blur)
                                if (isAnimated) {
                                    animationKeyframes.push({ type: "softness", frames: shadowParam.v.k });
                                } else {
                                    staticValues.shadowSoftness = (staticValue / 97) * 18;
                                }
                                break;
                        }
                    });
                }
            }
        });
    }

    if (!hasDropShadow) return "";

    // ✅ Convert AE direction & distance → offsetX, offsetY
    let angleRad = (staticValues.shadowDirection * Math.PI) / 180;
    let offsetX = Math.sin(angleRad) * staticValues.shadowDistance;
    let offsetY = -Math.cos(angleRad) * staticValues.shadowDistance;
    let blurRadius = staticValues.shadowSoftness;
    let finalShadowColor = convertColor(staticValues.shadowColor, staticValues.shadowOpacity);

    if (animationKeyframes.length === 0) {
        return `#${layer.ln} { filter: drop-shadow(${offsetX}px ${offsetY}px ${blurRadius}px ${finalShadowColor}); }\n`;
    }

    keyframes = `
@keyframes ${animationName} {
${generateShadowKeyframes(animationKeyframes, frameRate, totalFrames, startFrame, staticValues, layer)}
}`;

    cssRules += `#${layer.ln} { animation: ${animationName} ${totalFrames / frameRate}s ease-in-out infinite; }\n`;

    return keyframes + "\n" + cssRules;
}


// ✅ Convert Lottie Color to CSS RGBA (Uses ADBE Drop Shadow-0002 for Alpha)
function convertColor(colorArray, shadowOpacity) {
    if (!Array.isArray(colorArray) || colorArray.length < 3) return "rgba(0, 0, 0, 1)";
    
    // Extract RGB values from Lottie color
    let r = Math.round(colorArray[0] * 255);
    let g = Math.round(colorArray[1] * 255);
    let b = Math.round(colorArray[2] * 255);

    // ✅ Use the Lottie drop shadow opacity (`ADBE Drop Shadow-0002`)
    let alpha = shadowOpacity !== undefined ? shadowOpacity : (colorArray.length > 3 ? colorArray[3] : 1);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


// function calcMotionCurve(currentFrame) {

//     if (currentFrame && currentFrame.o) {
//         const x1 = Array.isArray(currentFrame.o.x) ? currentFrame.o.x[0] : currentFrame.o.x;
//         const y1 = Array.isArray(currentFrame.o.y) ? currentFrame.o.y[0] : currentFrame.o.y;
//         const x2 = Array.isArray(currentFrame.i.x) ? currentFrame.i.x[0] : currentFrame.i.x;
//         const y2 = Array.isArray(currentFrame.i.y) ? currentFrame.i.y[0] : currentFrame.i.y;

//         if (x1 !== undefined && y1 !== undefined && x2 !== undefined && y2 !== undefined) {
//             return `cubic-bezier(${x1}, ${y1}, ${x2}, ${y2})`;
//         }
//     }
//     return 'linear';
// }



function generateShadowKeyframes(animationKeyframes, frameRate, totalFrames, startFrame, staticValues, layer) {
    let keyframeOutput = "";
    let mergedFrames = {}; // Store computed values per percentage

    animationKeyframes.forEach((anim) => {
        anim.frames.forEach((frame, index, array) => {
            const percent = Math.round(((frame.t - startFrame) / totalFrames) * 100);
            
            if (!mergedFrames[percent]) {
                mergedFrames[percent] = {
                    opacity: staticValues.shadowOpacity,
                    color: staticValues.shadowColor,
                    distance: staticValues.shadowDistance,
                    direction: staticValues.shadowDirection,
                    softness: staticValues.shadowSoftness,
                    easing: 'linear'
                };
            }

            let value = frame.s[0];

            switch (anim.type) {
                case "opacity":
                    mergedFrames[percent].opacity = value / 255;
                    break;
                case "color":
                    mergedFrames[percent].color = value.slice(0, 3);
                    break;
                case "direction":
                    mergedFrames[percent].direction = value;
                    break;
                case "distance":
                    mergedFrames[percent].distance = value;
                    break;
                case "softness":
                    mergedFrames[percent].softness = (value / 97) * 18;
                    break;
            }

            if (index < array.length - 1) {
                let nextFrame = array[index + 1];
                let easing = calcMotionCurve(frame, nextFrame);
                if (easing !== 'linear') {
                    mergedFrames[percent].easing = easing;
                }
            }
        });
    });

    // ✅ Ensure 0% keyframe exists (duplicate first keyframe if needed)
    const keyframeEntries = Object.entries(mergedFrames).sort(([a], [b]) => a - b);
    if (!mergedFrames[0] && keyframeEntries.length > 0) {
        const [firstPercent, firstData] = keyframeEntries[0];
        mergedFrames[0] = { ...firstData, easing: 'linear' }; // Copy first available keyframe to 0%
    }

    // ✅ Ensure 100% keyframe exists (duplicate last keyframe if needed)
    if (!mergedFrames[100] && keyframeEntries.length > 0) {
        const [lastPercent, lastData] = keyframeEntries[keyframeEntries.length - 1];
        mergedFrames[100] = { ...lastData, easing: 'linear' }; // Copy last available keyframe to 100%
    }

    // ✅ Generate final keyframe CSS
    const sortedKeyframeEntries = Object.entries(mergedFrames).sort(([a], [b]) => a - b);
    for (let i = 0; i < sortedKeyframeEntries.length; i++) {
        const [percent, { opacity, color, distance, direction, softness, easing }] = sortedKeyframeEntries[i];

        let angleRad = (direction * Math.PI) / 180;
        let offsetX = Math.sin(angleRad) * distance;
        let offsetY = -Math.cos(angleRad) * distance;

        let finalShadowColor = `rgba(${Math.round(color[0] * 255)}, ${Math.round(color[1] * 255)}, ${Math.round(color[2] * 255)}, ${opacity})`;

        keyframeOutput += `  ${percent}% { filter: drop-shadow(${offsetX}px ${offsetY}px ${softness}px ${finalShadowColor}); animation-timing-function: ${easing}; }\n`;
        let animObject={
            property: 'Shadow',
            layerName:layer.ln,
            offset: percent/100,
            easing: easing,
            time: percent*(totalFrames/frameRate)*10,
            value: `drop-shadow(${offsetX}px ${offsetY}px ${softness}px ${finalShadowColor})`,
            duration: totalFrames*frameRate
        }
        shapeMorph.push(animObject)
    }

    return keyframeOutput;
}


  

function calcMotionCurve(currentFrame) {
    if (currentFrame && currentFrame.o) {
      const x1 = Array.isArray(currentFrame.o.x) ? currentFrame.o.x[0] : currentFrame.o.x;
      const y1 = Array.isArray(currentFrame.o.y) ? currentFrame.o.y[0] : currentFrame.o.y;
      const x2 = Array.isArray(currentFrame.i.x) ? currentFrame.i.x[0] : currentFrame.i.x;
      const y2 = Array.isArray(currentFrame.i.y) ? currentFrame.i.y[0] : currentFrame.i.y;
  
      if (x1 !== undefined && y1 !== undefined && x2 !== undefined && y2 !== undefined) {
        return `cubic-bezier(${x1}, ${y1}, ${x2}, ${y2})`;
      }
    }
    return 'linear';
  }


  function isClose(a, b, tolerance = .0001) {
    return Math.abs(a - b) < tolerance;
}



function generateGradientCSS(layer, lottieJSON = {}) {

    const frameRate = lottieJSON.fr || 30;
    const ip = lottieJSON.ip || 0;
    const op = lottieJSON.op || layer.op;
    const totalFrames = op - ip;
    const duration = totalFrames / frameRate;

    const gradientShape = layer?.shapes?.find(shape => shape.ty === "gr")
        ?.it?.find(item => item.ty === "gf" || item.ty === "rd"); // gf for linear, rd for radial

    if (!gradientShape) return "";

    const isAnimated = gradientShape.g.k.a === 1;
    const startPointAnimated = gradientShape.s.a === 1;
    const endPointAnimated = gradientShape.e?.a === 1;

    const keyframes = [];
    let gradientId = null;
    let stopIds = [];

    // Query DOM to find matching gradient
    const defs = document.querySelectorAll("defs linearGradient, defs radialGradient");
    defs.forEach((gradient) => {
        const gradientType = gradient.tagName;
        const id = gradient.id;

        if (gradientType === "linearGradient" && gradientShape.e) {
            const x1 = parseFloat(gradient.getAttribute("x1")) || 0;
            const y1 = parseFloat(gradient.getAttribute("y1")) || 0;
            const x2 = parseFloat(gradient.getAttribute("x2")) || 0;
            const y2 = parseFloat(gradient.getAttribute("y2")) || 0;

            const lottieStart = Array.isArray(gradientShape.s.k) ? gradientShape.s.k : gradientShape.s.k[0].s;
            const lottieEnd = Array.isArray(gradientShape.e.k) ? gradientShape.e.k : gradientShape.e.k[0].s;

            const lottieX1 = lottieStart[0];
            const lottieY1 = lottieStart[1];
            const lottieX2 = lottieEnd[0];
            const lottieY2 = lottieEnd[1];

            if (
                isClose(x1, lottieX1) &&
                isClose(y1, lottieY1) &&
                isClose(x2, lottieX2) &&
                isClose(y2, lottieY2)
            ) {
                gradientId = id;
                stopIds = Array.from(gradient.querySelectorAll("stop")).map(stop => stop.id);
            }
        } else if (gradientType === "radialGradient" && !gradientShape.e) {
            const cx = parseFloat(gradient.getAttribute("cx")) || 0;
            const cy = parseFloat(gradient.getAttribute("cy")) || 0;
            const r = parseFloat(gradient.getAttribute("r") || gradient.getAttribute("fr")) || 0;

            const lottieCenter = Array.isArray(gradientShape.s.k) ? gradientShape.s.k : gradientShape.s.k[0].s;
            const lottieR = gradientShape.r ? (Array.isArray(gradientShape.r.k) ? gradientShape.r.k : gradientShape.r.k[0]) : 0;

            const lottieCX = lottieCenter[0];
            const lottieCY = lottieCenter[1];

            if (
                isClose(cx, lottieCX) &&
                isClose(cy, lottieCY) &&
                (!lottieR || isClose(r, lottieR))
            ) {
                gradientId = id;
                stopIds = Array.from(gradient.querySelectorAll("stop")).map(stop => stop.id);
            }
        }
    });

    if (!gradientId) {
        console.warn("No matching gradient found in DOM");
        return "";
    }

    const selector = gradientId; // Use the DOM-derived ID directly

    if (!isAnimated && !startPointAnimated && !endPointAnimated) {
        return `#${selector.replace('__lottie', 'motionspec')} {}`;
    }

    const allTimes = new Set();
    if (isAnimated) gradientShape.g.k.k.forEach(frame => allTimes.add(frame.t));
    if (startPointAnimated) gradientShape.s.k.forEach(frame => allTimes.add(frame.t));
    if (endPointAnimated && gradientShape.e) gradientShape.e.k.forEach(frame => allTimes.add(frame.t));
    
    if (isAnimated || startPointAnimated || endPointAnimated) {
        allTimes.add(ip);
        allTimes.add(op);
    }

    const sortedTimes = Array.from(allTimes).sort((a, b) => a - b);
    const numStops = gradientShape.g.p;
    const keyframeData = gradientShape.g.k.k;
    
    const hasOpacityStops = keyframeData[0].s.length > numStops * 4;
    const colorValuesPerStop = 4;
    const colorStopLength = numStops * colorValuesPerStop;
    const numOpacityStops = hasOpacityStops ? (keyframeData[0].s.length - colorStopLength) / 2 : 0;

    function getStopData(frame, stopIndex) {
        const baseIndex = stopIndex * colorValuesPerStop;
        const position = frame.s[baseIndex];
        const r = Math.round(frame.s[baseIndex + 1] * 255);
        const g = Math.round(frame.s[baseIndex + 2] * 255);
        const b = Math.round(frame.s[baseIndex + 3] * 255);

        let opacity = 1;
        if (hasOpacityStops) {
            const opacityStops = [];
            for (let i = 0; i < numOpacityStops; i++) {
                const posIndex = colorStopLength + (i * 2);
                const opacityIndex = posIndex + 1;
                opacityStops.push({
                    position: frame.s[posIndex],
                    value: frame.s[opacityIndex]
                });
            }

            opacityStops.sort((a, b) => a.position - b.position);

            for (let i = 0; i < opacityStops.length - 1; i++) {
                if (position >= opacityStops[i].position && position <= opacityStops[i + 1].position) {
                    const t = (position - opacityStops[i].position) / 
                             (opacityStops[i + 1].position - opacityStops[i].position);
                    opacity = opacityStops[i].value + t * (opacityStops[i + 1].value - opacityStops[i].value);
                    break;
                }
            }
            if (position < opacityStops[0].position) opacity = opacityStops[0].value;
            if (position > opacityStops[opacityStops.length - 1].position) 
                opacity = opacityStops[opacityStops.length - 1].value;
        }

        return { r, g, b, opacity };
    }

    // Handle gradient position animation
    if (startPointAnimated || endPointAnimated) {
        let positionKeyframes = `@keyframes ${selector.replace('__lottie', 'motionspec')}_position {\n`;
        
        sortedTimes.forEach((time, index) => {
            const percent = ((time - ip) / totalFrames) * 100;
            
            let startFrame = startPointAnimated ? 
                gradientShape.s.k.find(f => f.t === time) || 
                (time === ip ? gradientShape.s.k[0] : 
                time === op ? gradientShape.s.k[gradientShape.s.k.length - 1] : 
                gradientShape.s.k.find(f => f.t < time) || gradientShape.s.k[gradientShape.s.k.length - 1]) : 
                { s: gradientShape.s.k };
            
            let endFrame = endPointAnimated && gradientShape.e ? 
                gradientShape.e.k.find(f => f.t === time) || 
                (time === ip ? gradientShape.e.k[0] : 
                time === op ? gradientShape.e.k[gradientShape.e.k.length - 1] : 
                gradientShape.e.k.find(f => f.t < time) || gradientShape.e.k[gradientShape.e.k.length - 1]) : 
                { s: gradientShape.e?.k };

            const sx = startFrame.s[0];
            const sy = startFrame.s[1];
            const ex = endFrame?.s[0] || 0;
            const ey = endFrame?.s[1] || 0;

            const dx = ex - sx;
            const dy = ey - sy;
            const angle = Math.atan2(dy, dx) * 180 / Math.PI;

            let timingFunction = '';
            if (index < sortedTimes.length - 1) {
                const isSynthesized = !(startPointAnimated && gradientShape.s.k.some(f => f.t === time)) && 
                                   !(endPointAnimated && gradientShape.e?.k.some(f => f.t === time));
                timingFunction = `  animation-timing-function: ${isSynthesized ? 'linear' : calcMotionCurve(startFrame)};\n`;
            }

            positionKeyframes += `  ${percent}% {\n    gradientTransform: translate(${sx}px, ${sy}px) rotate(${angle}deg);\n${timingFunction}  }\n`;
        });

        positionKeyframes += `}`;
        keyframes.push(positionKeyframes);
        keyframes.push(`#${selector.replace('__lottie', 'motionspec')} { animation: ${selector.replace('__lottie', 'motionspec')}_position ${duration}s infinite; }`);
    }

    // Generate keyframes for each color stop using DOM stop IDs
    for (let stopIndex = 0; stopIndex < Math.min(numStops, stopIds.length); stopIndex++) {
       
        const stopId = stopIds[stopIndex];
        let keyframeCSS = `@keyframes ${stopId.replace('__lottie', 'motionspec')}_animation {\n`;

        sortedTimes.forEach((time, index) => {
            const percent = ((time - ip) / totalFrames) * 100;
            let frame = keyframeData.find(f => f.t === time);

            if (!frame) {
                if (time === ip) frame = { ...keyframeData[0], t: ip };
                else if (time === op) frame = { ...keyframeData[keyframeData.length - 1], t: op };
                else frame = keyframeData.find(f => f.t < time) || keyframeData[keyframeData.length - 1];
            }

            const { r, g, b, opacity } = getStopData(frame, stopIndex);

            let timingFunction = '';
            if (index < sortedTimes.length - 1) {
                const isSynthesized = !keyframeData.some(f => f.t === time);
                timingFunction = `  animation-timing-function: ${isSynthesized ? 'linear' : calcMotionCurve(frame)};\n`;
            }

            keyframeCSS += `  ${percent}% {\n    stop-color: rgb(${r},${g},${b});\n    stop-opacity: ${opacity};\n${timingFunction}  }\n`;

            let animObject={
                property: 'Gradient',
                layerName:layer.ln,
                offset: percent/100,
                easing: calcMotionCurve(frame),
                time: percent*(totalFrames/frameRate)*10,
                value: `rgb(${r},${g},${b})`,
                duration: totalFrames*frameRate
            }
            shapeMorph.push(animObject)
        });

        keyframeCSS += `}`;
        keyframes.push(keyframeCSS);
        keyframes.push(`#${stopId.replace('__lottie', 'motionspec')} { animation: ${stopId.replace('__lottie', 'motionspec')}_animation ${duration}s infinite; }`);
    }

    return keyframes.join('\n').trim();
}












// function generateGradientCSS(layer, lottieJSON = {}) {
//     const frameRate = lottieJSON.fr || 30;
//     const ip = lottieJSON.ip || 0;
//     const op = lottieJSON.op || layer.op;
//     const totalFrames = op - ip;
//     const duration = totalFrames / frameRate;

//     const selector = `${layer.ln.replace(/\s/g, '_')}_gradient`;

//     const gradientShape = layer.shapes.find(shape => shape.ty === "gr")
//         ?.it.find(item => item.ty === "gf");

//     if (!gradientShape) return "";

//     const isAnimated = gradientShape.g.k.a === 1;
//     const startPointAnimated = gradientShape.s.a === 1;
//     const endPointAnimated = gradientShape.e.a === 1;

//     const keyframes = [];
//     keyframes.push(`#${selector} {}`);

//     if (!isAnimated && !startPointAnimated && !endPointAnimated) {
//         return keyframes.join('\n').trim();
//     }

//     const allTimes = new Set();
//     if (isAnimated) gradientShape.g.k.k.forEach(frame => allTimes.add(frame.t));
//     if (startPointAnimated) gradientShape.s.k.forEach(frame => allTimes.add(frame.t));
//     if (endPointAnimated) gradientShape.e.k.forEach(frame => allTimes.add(frame.t));
    
//     if (isAnimated || startPointAnimated || endPointAnimated) {
//         allTimes.add(ip);
//         allTimes.add(op);
//     }

//     const sortedTimes = Array.from(allTimes).sort((a, b) => a - b);
//     const numStops = gradientShape.g.p;
//     const keyframeData = gradientShape.g.k.k;
    
//     const hasOpacityStops = keyframeData[0].s.length > numStops * 4;
//     const colorValuesPerStop = 4; // position, r, g, b
//     const colorStopLength = numStops * colorValuesPerStop;
//     const numOpacityStops = hasOpacityStops ? (keyframeData[0].s.length - colorStopLength) / 2 : 0;

//     function getStopData(frame, stopIndex, time) {
//         // Get color data
//         const baseIndex = stopIndex * colorValuesPerStop;
//         const position = frame.s[baseIndex];
//         const r = Math.round(frame.s[baseIndex + 1] * 255);
//         const g = Math.round(frame.s[baseIndex + 2] * 255);
//         const b = Math.round(frame.s[baseIndex + 3] * 255);

//         // Calculate opacity by interpolating between opacity stops
//         let opacity = 1;
//         if (hasOpacityStops) {
//             const opacityStops = [];
//             for (let i = 0; i < numOpacityStops; i++) {
//                 const posIndex = colorStopLength + (i * 2);
//                 const opacityIndex = posIndex + 1;
//                 opacityStops.push({
//                     position: frame.s[posIndex],
//                     value: frame.s[opacityIndex]
//                 });
//             }

//             // Sort opacity stops by position
//             opacityStops.sort((a, b) => a.position - b.position);

//             // Interpolate opacity based on color stop position
//             for (let i = 0; i < opacityStops.length - 1; i++) {
//                 if (position >= opacityStops[i].position && position <= opacityStops[i + 1].position) {
//                     const t = (position - opacityStops[i].position) / 
//                              (opacityStops[i + 1].position - opacityStops[i].position);
//                     opacity = opacityStops[i].value + t * (opacityStops[i + 1].value - opacityStops[i].value);
//                     break;
//                 }
//             }
//             // Handle edge cases
//             if (position < opacityStops[0].position) opacity = opacityStops[0].value;
//             if (position > opacityStops[opacityStops.length - 1].position) 
//                 opacity = opacityStops[opacityStops.length - 1].value;
//         }

//         return { r, g, b, opacity };
//     }

//     // Generate keyframes for each stop
//     for (let stopIndex = 0; stopIndex < numStops; stopIndex++) {
//         const stopId = `${selector}_stop${stopIndex + 1}`;
//         let keyframeCSS = `@keyframes ${stopId}_animation {\n`;

//         sortedTimes.forEach((time, index) => {
//             const percent = ((time - ip) / totalFrames) * 100;
//             let frame = keyframeData.find(f => f.t === time);

//             if (!frame) {
//                 if (time === ip) frame = { ...keyframeData[0], t: ip };
//                 else if (time === op) frame = { ...keyframeData[keyframeData.length - 1], t: op };
//                 else frame = keyframeData.find(f => f.t < time) || keyframeData[keyframeData.length - 1];
//             }

//             const { r, g, b, opacity } = getStopData(frame, stopIndex, time);

//             let timingFunction = '';
//             if (index < sortedTimes.length - 1) {
//                 const isSynthesized = !keyframeData.some(f => f.t === time);
//                 timingFunction = `  animation-timing-function: ${isSynthesized ? 'linear' : calcMotionCurve(frame)};\n`;
//             }

//             keyframeCSS += `  ${percent}% {\n    stop-color: rgb(${r},${g},${b});\n    stop-opacity: ${opacity};\n${timingFunction}  }\n`;
//         });

//         keyframeCSS += `}`;
//         keyframes.push(keyframeCSS);
//         keyframes.push(`#${stopId} { animation: ${stopId}_animation ${duration}s infinite; }`);
//     }

//     return keyframes.join('\n').trim();
// }






// function generateGradientCSS(layer, lottieJSON = {}) {
//     const frameRate = lottieJSON.fr || 30;
//     const ip = lottieJSON.ip || 0;
//     const op = lottieJSON.op || layer.op;
//     const totalFrames = op - ip;
//     const duration = totalFrames / frameRate;

//     const selector = `${layer.ln.replace(/\s/g, '_')}_gradient`;
   

//     const gradientShape = layer.shapes.find(shape => shape.ty === "gr")
//         ?.it.find(item => item.ty === "gf");

//     if (!gradientShape) return "";

//     const isAnimated = gradientShape.g.k.a === 1;
//     const startPointAnimated = gradientShape.s.a === 1;
//     const endPointAnimated = gradientShape.e.a === 1;

//     const keyframes = [];

//     if (!isAnimated && !startPointAnimated && !endPointAnimated) {
//         return `#${selector} {}`; 
//     }

//     const allTimes = new Set();
//     if (isAnimated) gradientShape.g.k.k.forEach(frame => allTimes.add(frame.t));
//     if (startPointAnimated) gradientShape.s.k.forEach(frame => allTimes.add(frame.t));
//     if (endPointAnimated) gradientShape.e.k.forEach(frame => allTimes.add(frame.t));
    
//     if (isAnimated || startPointAnimated || endPointAnimated) {
//         allTimes.add(ip);
//         allTimes.add(op);
//     }

//     const sortedTimes = Array.from(allTimes).sort((a, b) => a - b);
//     const numStops = gradientShape.g.p;
//     const keyframeData = gradientShape.g.k.k;
    
//     const hasOpacityStops = keyframeData[0].s.length > numStops * 4;
//     const colorValuesPerStop = 4;
//     const colorStopLength = numStops * colorValuesPerStop;
//     const numOpacityStops = hasOpacityStops ? (keyframeData[0].s.length - colorStopLength) / 2 : 0;

//     function getStopData(frame, stopIndex) {
//         const baseIndex = stopIndex * colorValuesPerStop;
//         const position = frame.s[baseIndex];
//         const r = Math.round(frame.s[baseIndex + 1] * 255);
//         const g = Math.round(frame.s[baseIndex + 2] * 255);
//         const b = Math.round(frame.s[baseIndex + 3] * 255);

//         let opacity = 1;
//         if (hasOpacityStops) {
//             const opacityStops = [];
//             for (let i = 0; i < numOpacityStops; i++) {
//                 const posIndex = colorStopLength + (i * 2);
//                 const opacityIndex = posIndex + 1;
//                 opacityStops.push({
//                     position: frame.s[posIndex],
//                     value: frame.s[opacityIndex]
//                 });
//             }

//             opacityStops.sort((a, b) => a.position - b.position);

//             for (let i = 0; i < opacityStops.length - 1; i++) {
//                 if (position >= opacityStops[i].position && position <= opacityStops[i + 1].position) {
//                     const t = (position - opacityStops[i].position) / 
//                              (opacityStops[i + 1].position - opacityStops[i].position);
//                     opacity = opacityStops[i].value + t * (opacityStops[i + 1].value - opacityStops[i].value);
//                     break;
//                 }
//             }
//             if (position < opacityStops[0].position) opacity = opacityStops[0].value;
//             if (position > opacityStops[opacityStops.length - 1].position) 
//                 opacity = opacityStops[opacityStops.length - 1].value;
//         }

//         return { r, g, b, opacity };
//     }

//     // Handle gradient position animation
//     if (startPointAnimated || endPointAnimated) {
//         let positionKeyframes = `@keyframes ${selector}_position {\n`;
        
//         sortedTimes.forEach((time, index) => {
//             const percent = ((time - ip) / totalFrames) * 100;
            
//             let startFrame = startPointAnimated ? 
//                 gradientShape.s.k.find(f => f.t === time) || 
//                 (time === ip ? gradientShape.s.k[0] : 
//                 time === op ? gradientShape.s.k[gradientShape.s.k.length - 1] : 
//                 gradientShape.s.k.find(f => f.t < time) || gradientShape.s.k[gradientShape.s.k.length - 1]) : 
//                 { s: gradientShape.s.k };
            
//             let endFrame = endPointAnimated ? 
//                 gradientShape.e.k.find(f => f.t === time) || 
//                 (time === ip ? gradientShape.e.k[0] : 
//                 time === op ? gradientShape.e.k[gradientShape.e.k.length - 1] : 
//                 gradientShape.e.k.find(f => f.t < time) || gradientShape.e.k[gradientShape.e.k.length - 1]) : 
//                 { s: gradientShape.e.k };

//             const sx = startFrame.s[0];
//             const sy = startFrame.s[1];
//             const ex = endFrame.s[0];
//             const ey = endFrame.s[1];

//             const dx = ex - sx;
//             const dy = ey - sy;
//             const angle = Math.atan2(dy, dx) * 180 / Math.PI;

//             let timingFunction = '';
//             if (index < sortedTimes.length - 1) {
//                 const isSynthesized = !(startPointAnimated && gradientShape.s.k.some(f => f.t === time)) && 
//                                    !(endPointAnimated && gradientShape.e.k.some(f => f.t === time));
//                 timingFunction = `  animation-timing-function: ${isSynthesized ? 'linear' : calcMotionCurve(startFrame)};\n`;
//             }

//             positionKeyframes += `  ${percent}% {\n    gradientTransform: translate(${sx}px, ${sy}px) rotate(${angle}deg);\n${timingFunction}  }\n`;
//         });

//         positionKeyframes += `}`;
//         keyframes.push(positionKeyframes);
//         keyframes.push(`#${selector} { animation: ${selector}_position ${duration}s infinite; }`);
//     }

//     // Generate keyframes for each color stop
//     for (let stopIndex = 0; stopIndex < numStops; stopIndex++) {
//         const stopId = `${selector}_stop${stopIndex + 1}`;
//         let keyframeCSS = `@keyframes ${stopId}_animation {\n`;

//         sortedTimes.forEach((time, index) => {
//             const percent = ((time - ip) / totalFrames) * 100;
//             let frame = keyframeData.find(f => f.t === time);

//             if (!frame) {
//                 if (time === ip) frame = { ...keyframeData[0], t: ip };
//                 else if (time === op) frame = { ...keyframeData[keyframeData.length - 1], t: op };
//                 else frame = keyframeData.find(f => f.t < time) || keyframeData[keyframeData.length - 1];
//             }

//             const { r, g, b, opacity } = getStopData(frame, stopIndex);

//             let timingFunction = '';
//             if (index < sortedTimes.length - 1) {
//                 const isSynthesized = !keyframeData.some(f => f.t === time);
//                 timingFunction = `  animation-timing-function: ${isSynthesized ? 'linear' : calcMotionCurve(frame)};\n`;
//             }

//             keyframeCSS += `  ${percent}% {\n    stop-color: rgb(${r},${g},${b});\n    stop-opacity: ${opacity};\n${timingFunction}  }\n`;
//         });

//         keyframeCSS += `}`;
//         keyframes.push(keyframeCSS);
//         keyframes.push(`#${stopId} { animation: ${stopId}_animation ${duration}s infinite; }`);
//     }

//     return keyframes.join('\n').trim();
// }




  
//   function generateGradientCSS(layer, lottieJSON = {}) {
//     const frameRate = lottieJSON.fr || 30;
//     const ip = lottieJSON.ip || 0;
//     const op = lottieJSON.op || layer.op;
//     const totalFrames = op - ip;
//     const duration = totalFrames / frameRate;
  
//     const selector = `${layer.ln.replace(/\s/g, '_')}_gradient`;
  
//     const gradientShape = layer.shapes.find(shape => shape.ty === "gr")
//       ?.it.find(item => item.ty === "gf");
  
//     if (!gradientShape) return "";
  
//     const isAnimated = gradientShape.g.k.a === 1;
//     const startPointAnimated = gradientShape.s.a === 1;
//     const endPointAnimated = gradientShape.e.a === 1;
  
//     const keyframes = [];
  
//     // Always define the base selector for the gradient
//     keyframes.push(`#${selector} {}`); // Ensures the gradient ID exists even if static
  
//     if (!isAnimated && !startPointAnimated && !endPointAnimated) {
//       return keyframes.join('\n').trim(); // No animations, just the base selector
//     }
  
//     const allTimes = new Set();
//     const staticStops = isAnimated ? gradientShape.g.k.k[0].s : gradientShape.g.k.s;
//     const staticStart = startPointAnimated ? gradientShape.s.k[0].s : gradientShape.s.k;
//     const staticEnd = endPointAnimated ? gradientShape.e.k[0].s : gradientShape.e.k;
  
//     // Collect times only for animated properties
//     if (isAnimated) gradientShape.g.k.k.forEach(frame => allTimes.add(frame.t));
//     if (startPointAnimated) gradientShape.s.k.forEach(frame => allTimes.add(frame.t));
//     if (endPointAnimated) gradientShape.e.k.forEach(frame => allTimes.add(frame.t));
    
//     // Add ip and op only if there’s at least one animated property
//     if (isAnimated || startPointAnimated || endPointAnimated) {
//       allTimes.add(ip);
//       allTimes.add(op);
//     }
//     const sortedTimes = Array.from(allTimes).sort((a, b) => a - b);
  
//     // Generate keyframes for colors/opacity only if animated
//     if (isAnimated) {
//       const numStops = gradientShape.g.p;
//       for (let stopIndex = 0; stopIndex < numStops; stopIndex++) {
//         const stopId = `${selector}_stop${stopIndex + 1}`;
//         let keyframeCSS = `@keyframes ${stopId}_animation {\n`;
  
//         sortedTimes.forEach((time, index) => {
//           const percent = ((time - ip) / totalFrames) * 100;
//           let frame = gradientShape.g.k.k.find(f => f.t === time);
  
//           if (!frame) {
//             if (time === ip) frame = { ...gradientShape.g.k.k[0], t: ip };
//             else if (time === op) frame = { ...gradientShape.g.k.k[gradientShape.g.k.k.length - 1], t: op };
//             else frame = gradientShape.g.k.k.find(f => f.t < time) || gradientShape.g.k.k[gradientShape.g.k.k.length - 1];
//           }
  
//           const offset = frame ? frame.s[stopIndex * 4] * 100 : staticStops[stopIndex * 4] * 100;
//           const r = Math.round((frame ? frame.s[stopIndex * 4 + 1] : staticStops[stopIndex * 4 + 1]) * 255);
//           const g = Math.round((frame ? frame.s[stopIndex * 4 + 2] : staticStops[stopIndex * 4 + 2]) * 255);
//           const b = Math.round((frame ? frame.s[stopIndex * 4 + 3] : staticStops[stopIndex * 4 + 3]) * 255);
//           const opacity = frame ? frame.s[stopIndex * 4 + 3] : staticStops[stopIndex * 4 + 3] !== undefined ? staticStops[stopIndex * 4 + 3] : 1;
  
//           let timingFunction = '';
//           if (index < sortedTimes.length - 1) {
//             const isSynthesized = !gradientShape.g.k.k.some(f => f.t === time);
//             timingFunction = `  animation-timing-function: ${isSynthesized ? 'linear' : calcMotionCurve(frame)};\n`;
//           }
  
//           keyframeCSS += `  ${percent}% {\n    stop-color: rgb(${r},${g},${b});\n    stop-opacity: ${opacity};\n${timingFunction}  }\n`;
//         });
  
//         keyframeCSS += `}`;
//         keyframes.push(keyframeCSS);
//         keyframes.push(`#${stopId} { animation: ${stopId}_animation ${duration}s infinite; }`);
//       }
//     }
  
//     // Generate position keyframes only if start or end points are animated
//     if (startPointAnimated || endPointAnimated) {
//       let positionKeyframes = `@keyframes ${selector}_position {\n`;
//       sortedTimes.forEach((time, index) => {
//         const percent = ((time - ip) / totalFrames) * 100;
//         let startFrame = startPointAnimated ? gradientShape.s.k.find(f => f.t === time) : null;
//         let endFrame = endPointAnimated ? gradientShape.e.k.find(f => f.t === time) : null;
  
//         if (startPointAnimated && !startFrame) {
//           if (time === ip) startFrame = { ...gradientShape.s.k[0], t: ip };
//           else if (time === op) startFrame = { ...gradientShape.s.k[gradientShape.s.k.length - 1], t: op };
//           else startFrame = gradientShape.s.k.find(f => f.t < time) || gradientShape.s.k[gradientShape.s.k.length - 1];
//         }
//         if (endPointAnimated && !endFrame) {
//           if (time === ip) endFrame = { ...gradientShape.e.k[0], t: ip };
//           else if (time === op) endFrame = { ...gradientShape.e.k[gradientShape.e.k.length - 1], t: op };
//           else endFrame = gradientShape.e.k.find(f => f.t < time) || gradientShape.e.k[gradientShape.e.k.length - 1];
//         }
  
//         const [x1, y1] = startFrame ? startFrame.s : staticStart;
//         const [x2, y2] = endFrame ? endFrame.s : staticEnd;
  
//         let timingFunction = '';
//         if (index < sortedTimes.length - 1) {
//           const frame = startFrame || endFrame || 
//                         (isAnimated ? gradientShape.g.k.k.find(f => f.t === time) || gradientShape.g.k.k.find(f => f.t < time) : null) || 
//                         (startPointAnimated ? gradientShape.s.k[gradientShape.s.k.length - 1] : gradientShape.e.k[gradientShape.e.k.length - 1]);
//           const isSynthesized = (!startPointAnimated || !gradientShape.s.k.some(f => f.t === time)) &&
//                                 (!endPointAnimated || !gradientShape.e.k.some(f => f.t === time));
//           timingFunction = `  animation-timing-function: ${isSynthesized ? 'linear' : calcMotionCurve(frame)};\n`;
//         }
  
//         positionKeyframes += `  ${percent}% {\n    x1: ${x1};\n    y1: ${y1};\n    x2: ${x2};\n    y2: ${y2};\n${timingFunction}  }\n`;
//       });
  
//       positionKeyframes += `}`;
//       keyframes.push(positionKeyframes);
//       keyframes.push(`#${selector} { animation: ${selector}_position ${duration}s infinite; }`);
//     }
  
//     return keyframes.join('\n').trim();
//   }













  
//   function generateGradientCSS(layer) {
//     // Base selector using layer.nm + "_gradient"
//     const selector = `${layer.nm.replace(/\s/g, '_')}_gradient`; // Replace spaces with underscores for valid CSS
  
//     // Find the gradient fill (ty: "gf") in the shapes array
//     const gradientShape = layer.shapes.find(shape => shape.ty === "gr")
//       ?.it.find(item => item.ty === "gf");
  
//     if (!gradientShape) return ""; // No gradient found
  
//     // Gradient properties
//     const isAnimated = gradientShape.g.k.a === 1; // Check if gradient colors are animated
//     const startPointAnimated = gradientShape.s.a === 1; // Check if start point is animated
//     const endPointAnimated = gradientShape.e.a === 1; // Check if end point is animated
  
//     let css = "";
  
//     // Static gradient case (no animation at all)
//     if (!isAnimated && !startPointAnimated && !endPointAnimated) {
//       return css; // No CSS needed for static gradient, as it’s defined in SVG
//     }
  
//     // Animated gradient case
//     const keyframes = [];
//     const allTimes = new Set(); // Collect all keyframe times
  
//     // Static values if not animated
//     const staticStops = isAnimated ? gradientShape.g.k.k[0].s : gradientShape.g.k.s;
//     const staticStart = startPointAnimated ? gradientShape.s.k[0].s : gradientShape.s.k;
//     const staticEnd = endPointAnimated ? gradientShape.e.k[0].s : gradientShape.e.k;
  
//     // Collect all keyframe times
//     if (isAnimated) gradientShape.g.k.k.forEach(frame => allTimes.add(frame.t));
//     if (startPointAnimated) gradientShape.s.k.forEach(frame => allTimes.add(frame.t));
//     if (endPointAnimated) gradientShape.e.k.forEach(frame => allTimes.add(frame.t));
//     const sortedTimes = Array.from(allTimes).sort((a, b) => a - b);
  
//     // Generate keyframes for each stop (color and opacity)
//     if (isAnimated) {
//       const numStops = gradientShape.g.p;
//       for (let stopIndex = 0; stopIndex < numStops; stopIndex++) {
//         const stopId = `${selector}_stop${stopIndex + 1}`;
//         let keyframeCSS = `@keyframes ${stopId}_animation {\n`;
  
//         sortedTimes.forEach((time, index) => {
//           const percent = (time / layer.op) * 100;
//           const frame = isAnimated ? gradientShape.g.k.k.find(f => f.t === time) || gradientShape.g.k.k[gradientShape.g.k.k.length - 1] : null;
//           const offset = frame ? frame.s[stopIndex * 4] * 100 : staticStops[stopIndex * 4] * 100;
//           const r = Math.round((frame ? frame.s[stopIndex * 4 + 1] : staticStops[stopIndex * 4 + 1]) * 255);
//           const g = Math.round((frame ? frame.s[stopIndex * 4 + 2] : staticStops[stopIndex * 4 + 2]) * 255);
//           const b = Math.round((frame ? frame.s[stopIndex * 4 + 3] : staticStops[stopIndex * 4 + 3]) * 255);
//           const opacity = frame ? frame.s[stopIndex * 4 + 3] : staticStops[stopIndex * 4 + 3] !== undefined ? staticStops[stopIndex * 4 + 3] : 1;
  
//           let timingFunction = '';
//           if (index < sortedTimes.length - 1 && frame) {
//             timingFunction = `  animation-timing-function: ${calcMotionCurve(frame)};\n`;
//           }
  
//           keyframeCSS += `  ${percent}% {\n    stop-color: rgb(${r},${g},${b});\n    stop-opacity: ${opacity};\n${timingFunction}  }\n`;
//         });
  
//         keyframeCSS += `}`;
//         keyframes.push(keyframeCSS);
//         keyframes.push(`#${stopId} { animation: ${stopId}_animation ${layer.op / 30}s infinite; }`);
//       }
//     }
  
//     // Generate keyframes for position (start and end points)
//     if (startPointAnimated || endPointAnimated || isAnimated) {
//       let positionKeyframes = `@keyframes ${selector}_position {\n`;
//       sortedTimes.forEach((time, index) => {
//         const percent = (time / layer.op) * 100;
//         const startFrame = startPointAnimated ? gradientShape.s.k.find(f => f.t === time) || gradientShape.s.k[gradientShape.s.k.length - 1] : null;
//         const endFrame = endPointAnimated ? gradientShape.e.k.find(f => f.t === time) || gradientShape.e.k[gradientShape.e.k.length - 1] : null;
//         const [x1, y1] = startFrame ? startFrame.s : staticStart;
//         const [x2, y2] = endFrame ? endFrame.s : staticEnd;
  
//         let timingFunction = '';
//         if (index < sortedTimes.length - 1) {
//           const frame = startFrame || endFrame || (isAnimated ? gradientShape.g.k.k.find(f => f.t === time) : null);
//           if (frame) timingFunction = `  animation-timing-function: ${calcMotionCurve(frame)};\n`;
//         }
  
//         positionKeyframes += `  ${percent}% {\n    x1: ${x1};\n    y1: ${y1};\n    x2: ${x2};\n    y2: ${y2};\n${timingFunction}  }\n`;
//       });
  
//       positionKeyframes += `}`;
//       keyframes.push(positionKeyframes);
//       keyframes.push(`#${selector} { animation: ${selector}_position ${layer.op / 30}s infinite; }`);
//     }
  
//     // Combine all keyframes into a single CSS string
//     css = keyframes.join('\n');
//     return css.trim();
//   }
  


  
 

//Orginal

// export default function lottieToCSS(lottieJSON) {
//     const frameRate = lottieJSON.fr; // Frames per second
//     const totalFrames = lottieJSON.op - lottieJSON.ip; // Total animation frames
//     let cssAnimations = "";

//     lottieJSON.layers.forEach(layer => {
//         if (layer.ty !== 4 || !layer.shapes) return; // Only process shape layers

//         layer.shapes.forEach(shapeGroup => {
//             if (shapeGroup.ty !== "gr") return; // Only process shape groups
            
//             shapeGroup.it.forEach(shape => {
//                 if (shape.ty !== "sh" || !shape.ks.a) return; // Only process animated paths
                
//                 let keyframes = "";
               
//                 let shapeName = shape.ln.replace(/[^a-zA-Z0-9]/g, "_"); // Safe CSS class name
//                 let lastPercent = 0;
//                 let lastPath = null;

//                 shape.ks.k.forEach((frame) => {
//                     const percent = Math.round(((frame.t - lottieJSON.ip) / totalFrames) * 100);
//                     const path = convertLottiePathToSVG(frame.s[0]); // Convert Lottie path data to SVG `d`

//                     if (path) {
//                         keyframes += `  ${percent}% { d: path("${path}"); }\n`;
//                         lastPercent = percent;
//                         lastPath = path;
//                     }
//                 });

//                 // Ensure the last keyframe is at 100%
//                 if (lastPercent !== 100 && lastPath) {
//                     keyframes += `  100% { d: path("${lastPath}"); }\n`;
//                 }

//                 if (keyframes) {
//                     cssAnimations += `
// @keyframes morph_${shapeName} {
// ${keyframes}}
// .${shapeName} {
//   animation: morph_${shapeName} ${totalFrames / frameRate}s infinite ease-in-out;
// }
// `;
//                 }
//             });
//         });
//     });

//     return cssAnimations.trim(); // Return formatted CSS
// }

// // ✅ **Fixed Lottie Paths → SVG Paths Converter**
// function convertLottiePathToSVG(shapeData) {
//     if (!shapeData || !shapeData.v || !shapeData.i || !shapeData.o) return null;

//     let path = `M${shapeData.v[0][0]},${shapeData.v[0][1]}`; // Start path at first vertex

//     for (let i = 0; i < shapeData.v.length; i++) {
//         const currentVertex = shapeData.v[i];
//         const nextIndex = (i + 1) % shapeData.v.length; // Wrap around for closed paths

//         const outCtrl = shapeData.o[i];  // Outgoing control point from current vertex
//         const inCtrl = shapeData.i[nextIndex];  // Incoming control point for next vertex
//         const nextVertex = shapeData.v[nextIndex]; // Next vertex

//         path += ` C${outCtrl[0]},${outCtrl[1]} ${inCtrl[0]},${inCtrl[1]} ${nextVertex[0]},${nextVertex[1]}`;
//     }

//     if (shapeData.c) path += " Z"; // Close the path if required

//     return path;
// }



