import React, { useState, useEffect, useRef } from "react";
import keyframeIcon from "../Images/Icons/keyframe.svg";
import { extractMotionData } from "./extractMotionData";
import { extractCSSMotionData } from "./extractCSSMotionData";
import ShapeComponent from "./ShapeComponent";
import "./LayerComponent.css";

const LayerComponent = ({ layer, fps, totalFrames, fluentCode, tooltipDirection, toggleSoloLayer, cssCode }) => {
    const [activeTooltipId, setActiveTooltipId] = useState(null);
    const [motionData, setMotionData] = useState(null);
    const [shapeMorphs, setShapeMorphs] = useState([]);
    const [hasAnimatedChildren, setHasAnimatedChildren] = useState(false);
    const [layerMatchName, setLayerMatchName] = useState(null);
    const componentRef = useRef(null);
    const propertyColors = {
        position: "#3A96DD",
        scale: "#1CB86D",
        rotation: "#da9c38",
        opacity: "#DA3633",
        anchor: "#F2CC60",
        size: "#4028db",
        fill: "#888888",
        "Gradient": "rgb(42, 56, 158)",
        "Shape Morph": 'rgb(255, 153, 0)',
        offsetPath: "#ba3add",
        visibility: 'transparent',
        "Border Radius": "rgb(162, 216, 56)",
        "Motion Path": "#ba3add",
        "Shadow": "rgb(185, 50, 127)",
    };

    useEffect(() => {
        let extractedData

        if (fluentCode && layer.nm) {


            let extractedData = extractMotionData(fluentCode, layer.nm.replace('#', '') + "_" + layer.ind);
            setMotionData(extractedData);

            // console.log(extractedData)


            let shapeMorph = JSON.parse(window.localStorage.getItem('shapeMorph')) || []; // Ensure it's an array

            if (shapeMorph.length > 0) {
                let updateMoData = [...extractedData]; // Create a copy of extractedData
            
                let shapeMorphKeyframes = {
                    keyframes: [],
                    duration: 0,
                    durationToken: null
                };
            
                shapeMorph.forEach((morph) => {
                    if (morph.layerName === layer.ln) {
                        shapeMorphKeyframes.keyframes.push({
                            "property": morph.property,
                            "transform": null,
                            "width": null,
                            "height": null,
                            "fill": null,
                            "opacity": null,
                            "offset": morph.offset,
                            "time": morph.time,
                            "easing": morph.easing,
                            "offsetPath": null,
                            "offsetDistance": null,
                            "visibility": null,
                            "rx": null,
                            "value": morph.value // Store path data
                        });
            
                        // Set duration to max morph duration
                        shapeMorphKeyframes.duration = Math.max(shapeMorphKeyframes.duration, morph.duration);
                    }
                });
            
                if (shapeMorphKeyframes.keyframes.length > 0) {
                    updateMoData.push(shapeMorphKeyframes); // Append the consolidated shape morph data
                }
            
                console.log(updateMoData);
                setMotionData(updateMoData); // Update state with new motion data
            }
            


            if (hasNestedAnimation(layer)) {
                setHasAnimatedChildren(true)
            }

        }
    }, [fluentCode, layer.nm]);

    const handleTooltipToggle = (id) => {

        setActiveTooltipId((prevId) => (prevId === id ? null : id));
    };

    const hasNestedAnimation = (layer) => {
        let hasAnimation = false;

        function checkForAnimation(obj) {
            if (!obj || typeof obj !== "object") return;

            // Check if `a: 1` exists anywhere in the object
            if (obj.a === 1) {
                hasAnimation = true;
            }

            // Recursively check properties inside the object
            Object.values(obj).forEach(value => {
                if (Array.isArray(value)) {
                    value.forEach(item => checkForAnimation(item));
                } else if (typeof value === "object") {
                    checkForAnimation(value);
                }
            });
        }

        // Check the main `ks` (keyframe properties)
        checkForAnimation(layer.ks);

        // Check the nested shape hierarchy
        if (Array.isArray(layer.shapes)) {
            layer.shapes.forEach(shape => checkForAnimation(shape));
        }

        return hasAnimation;
    }



    const formatNumber = (num) => {
        if (num === null || num === undefined) return null;
        const rounded = Math.round(num * 100) / 100;
        return Number.isInteger(rounded) ? rounded.toString() : rounded.toFixed(2);
    };

    const formatDelta = (start, end, isPx) => {
        if (start === null || end === null) return null;
        const delta = end - start;
        return `${delta >= 0 ? "+" : ""}${formatNumber(delta)}${isPx ? "px" : ""}`;
    };

    const formatColor = (rgba) => {
        if (!rgba) return null;
        return rgba.replace(/(\d+\.\d{3})\d*/g, "$1"); // Limit to 3 decimals
    };

    const calculateDelta = (startValue, endValue, propKey) => {
        if (!startValue || !endValue) return null;

        let deltaStr = "";
        const isPx = propKey === "position" || propKey === "size";

        if (propKey === "position") {
            deltaStr = `x: ${formatDelta(startValue.x, endValue.x, isPx)}; y: ${formatDelta(startValue.y, endValue.y, isPx)}`;
        } else if (propKey === "size") {
            deltaStr = `width: ${formatDelta(startValue.width, endValue.width, isPx)}; height: ${formatDelta(startValue.height, endValue.height, isPx)}`;
        } else if (propKey === "scale") {
            deltaStr = `scaleX: ${formatDelta(startValue.scaleX, endValue.scaleX, false)}; scaleY: ${formatDelta(startValue.scaleY, endValue.scaleY, false)}`;
        } else if (propKey === "rotation") {
            deltaStr = `angle: ${formatDelta(startValue.angle, endValue.angle, false)}deg`;
        } else if (propKey === "opacity") {
            deltaStr = `Delta: ${formatDelta(startValue, endValue, false)}`;
        }

        return deltaStr;
    };


    const formatValue = (value, propKey) => {
        if (value === null || value === undefined) {

            return null;
        }

        if (propKey === "position" || propKey === "size") {
            let formattedValue = [];

            if (value.x !== undefined) formattedValue.push(`x: ${formatNumber(value.x)}px`);
            if (value.y !== undefined) formattedValue.push(`y: ${formatNumber(value.y)}px`);
            if (value.width !== undefined) formattedValue.push(`width: ${formatNumber(value.width)}px`);
            if (value.height !== undefined) formattedValue.push(`height: ${formatNumber(value.height)}px`);

            return formattedValue.join(", ");
        } else if (propKey === "scale") {
            return `scaleX: ${formatNumber(value.scaleX)}, scaleY: ${formatNumber(value.scaleY)}`;
        } else if (propKey === "rotation") {
            return `angle: ${formatNumber(value.angle)}deg`;
        } else {
            return formatNumber(value);
        }
    };



    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const searchForUsageInRender = (searchTerm) => {
        if (!searchTerm) return;

        // Ensure first letter is capitalized
        searchTerm = capitalizeFirstLetter(searchTerm);

        console.log(`🔍 Searching for "${searchTerm}" in <span> elements inside <code> with "<" before it...`);

        // 🔍 Step 1: Find all <span> elements **inside** <code> blocks
        const spans = document.querySelectorAll("code span.token");

        let targetSpan = null;

        for (let i = 1; i < spans.length; i++) {  // Start from index 1 to check previous element
            const prevSpan = spans[i - 1];  // Previous span
            const currentSpan = spans[i];  // Current span

            if (prevSpan.innerHTML.includes("&lt;") && currentSpan.innerHTML.includes(searchTerm)) {
                targetSpan = currentSpan;
                console.log(`✅ Found "${searchTerm}" after "<" inside <code>. Scrolling now...`);
                break;
            }
        }

        if (!targetSpan) {
            console.log(`❌ "${searchTerm}" not found after "<" inside <code> blocks.`);
            return;
        }

        // 🔄 Scroll instantly with "start" alignment (puts span at the top)
        targetSpan.scrollIntoView({ behavior: "smooth", block: "start" });
    };


    const searchForMotionDef = (searchTerm) => {
        if (!searchTerm) return;

        // Ensure first letter is capitalized
        searchTerm = capitalizeFirstLetter(searchTerm);

        console.log(`🔍 Searching for "${searchTerm}" after "const" inside <code> blocks...`);

        // 🔍 Step 1: Find all <span> elements **inside** <code> blocks
        const spans = document.querySelectorAll("code span.token");

        let targetSpan = null;

        for (let i = 0; i < spans.length - 1; i++) {  // Ensure there's a next element
            const prevSpan = spans[i];  // Current span (should be "const")
            const nextSpan = spans[i + 1];  // Next span (should be searchTerm)

            if (prevSpan.innerHTML.trim() === "const" && nextSpan.innerHTML.includes(searchTerm)) {
                targetSpan = nextSpan;
                console.log(`✅ Found "${searchTerm}" after "const" inside <code>. Scrolling now...`);
                break;
            }
        }

        if (!targetSpan) {
            console.log(`❌ "${searchTerm}" not found after "const" inside <code> blocks.`);
            return;
        }

        // 🔄 Scroll instantly with "start" alignment (puts span at the top)
        targetSpan.scrollIntoView({ behavior: "smooth", block: "start" });
    };


    const searchForCSSDef = (searchTerm) => {
        if (!searchTerm) return;
    
    
        console.log(`🔍 Searching for "${searchTerm}" inside <code> blocks...`);
    
        // 🔍 Step 1: Find all <span> elements **inside** <code> blocks
        const spans = document.querySelectorAll("code span.token");
    
        let targetSpan = null;
    
        for (let i = 0; i < spans.length; i++) {
            const span = spans[i];  // Current span
    
            if (span.innerHTML.includes(searchTerm)) {
                targetSpan = span;
                // console.log(`✅ Found "${searchTerm}" inside <code>. Scrolling now...`);
                break;
            }
        }
    
        if (!targetSpan) {
            // console.log(`❌ "${searchTerm}" not found inside <code> blocks.`);
            return;
        }
    
        // 🔄 Scroll instantly with "start" alignment (puts span at the top)
        targetSpan.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    




    useEffect(() => {
        const handleClickOutside = (event) => {

            if (
                componentRef.current && // Ensure component exists

                !event.target.closest(".keyframe-segment") &&
                !event.target.closest(".keyframe") && /// Not a keyframe
                !event.target.closest(".tooltip") &&  !event.target.closest("#scrollToPath")
                
            ) {
                setActiveTooltipId(null); // Hide tooltips
                // console.log("🛑 Clicked outside – hiding all tooltips.");
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);




    const renderKeyframes = (keyframesData, propKey) => {
        //console.log(keyframesData)
        if (!keyframesData || !Array.isArray(keyframesData)) return null;
        const hasKeyframeAtStart = keyframesData.some((keyframe) => keyframe.offset === 0);
        let gradientStops = keyframesData.map((keyframe) => {
            const percentage = keyframe.offset * 100;
            const color = formatColor(keyframe.fill) || "rgba(255,255,255,1)";
            return `${color} ${percentage}%`;
        });

        if (propKey === "fill" && keyframesData.length > 0) {
            const lastKeyframe = keyframesData[keyframesData.length - 1];
            const lastColor = formatColor(lastKeyframe.fill) || "rgba(255,255,255,1)";
            gradientStops.push(`${lastColor} 100%`);
        }


        return (
            <div className="timeline-anim-container" ref={componentRef}>

                <div className="layer-parameter">{propKey.charAt(0).toUpperCase() + propKey.slice(1)}</div>
                <div className="keyframe-line-container">
                    <div
                        className="keyframeline"
                        style={{
                            position: "relative",
                            background: propKey === "visibility" ? "rgb(204, 132, 240)" : 'transparent',
                            marginLeft: `${(layer.ip / totalFrames) * 100}%`,
                            // width: `${((layer.op - layer.ip) / totalFrames) * 100 - (layer.ip / totalFrames) * 100 }%`,
                        }}
                    >
                        {keyframesData.map((keyframe, idx) => {
                            const nextKeyframe = keyframesData[idx + 1];
                            const keyframeId = `kf-${layer.nm}-${propKey}-${idx}`;
                            const segmentId = `trans-${keyframeId}`;
                            return (
                                <React.Fragment>
                                    {/* Keyframe Icon with Clickable Tooltip */}
                                    <div
                                        className="keyframe"
                                        key={keyframeId + idx}
                                        style={{
                                            position: "absolute",
                                            left: `${keyframe.offset * 100}%`,
                                            transform: "translate(-50%, -50%)",
                                            zIndex: 100000,
                                        }}

                                    >


                                        {/* Keyframe Tooltip */}
                                        {activeTooltipId === keyframeId && (
                                            <div className={tooltipDirection === "down" ? "tooltip" : "tooltip-down"} style={{ zIndex: 100001 + idx }}>
                                                <div><strong>{propKey.charAt(0).toUpperCase() + propKey.slice(1)} Keyframe</strong></div>
                                                {/* Display relevant value based on property type */}
                                                {propKey === "position" && keyframe.value && (
                                                    <div><strong>Value:</strong> x: {formatNumber(keyframe.value.x)}px, y: {formatNumber(keyframe.value.y)}px</div>
                                                )}
                                                {propKey === "scale" && keyframe.value && (
                                                    <div><strong>Value:</strong> scaleX: {formatNumber(keyframe.value.scaleX)}; scaleY: {formatNumber(keyframe.value.scaleY)}</div>
                                                )}
                                                {propKey === "rotation" && keyframe.value && (
                                                    <div><strong>Value:</strong> {formatNumber(keyframe.value.angle)}deg</div>
                                                )}
                                                {propKey === "opacity" && keyframe.value && (
                                                    <div><strong>Value:</strong> {formatNumber(keyframe.value.opacity)}</div>
                                                )}
                                                {propKey === "offsetPath" && keyframe.value && (
                                                    <div onClick={()=>{searchForCSSDef(keyframe.value)}} style={{textDecoration:'underline', color:'rgb(100, 100, 237)'}}><strong>Value:</strong>  Motion Path</div>
                                                )}
                                                {propKey === "size" && keyframe.value && (
                                                    <div><strong>Value:</strong> width: {formatNumber(keyframe.value.width)}px; height: {formatNumber(keyframe.value.height)}px</div>
                                                )}
                                                {propKey === "fill" && keyframe.fill && (
                                                    <div><strong>Value:</strong> {formatColor(keyframe.fill)}</div>
                                                )}
                                                {propKey === "visibility" && keyframe.visibility && (
                                                    <div><strong>Value: </strong> {formatColor(keyframe.value)}</div>
                                                )}
                                                {propKey === "Shadow" && keyframe.value && (
                                                    <div><strong>Value: </strong>{keyframe.value}</div>
                                                )}
                                                {propKey === "Gradient" && keyframe.value && (
                                                    <div><strong>Stop Color: </strong>{keyframe.value}</div>
                                                )}
                                                {propKey === "Shape Morph" && keyframe.value && (
                                                    <div><strong   id="scrollToPath" onClick={()=>{searchForCSSDef(keyframe.value)}} style={{textDecoration:'underline', color:'rgb(100, 100, 237)'}} >View Path</strong></div>
                                                )}
                                                <div><strong>Time:</strong> {formatNumber(keyframe.time)}ms</div>
                                                <div><strong>Offset:</strong> {formatNumber(keyframe.offset * 100)}%</div>


                                                {/* OffsetPath Details */}
                                                {propKey === "Motion Path" && keyframe.value && (
                                                    <>
                                                        <div onClick={() => {

                                                        }}><strong>Path: </strong>View In Code</div>
                                                        {keyframe.value.distance !== null && (
                                                            <div><strong>Offset Distance:</strong> {formatNumber(keyframe.value.distance)}%</div>
                                                        )}
                                                    </>
                                                )}

                                                {/* Fill Color Display */}
                                                {keyframe.fill && <div><strong>Color:</strong> {formatColor(keyframe.fill)}</div>}
                                            </div>
                                        )}

                                    </div>
                                    {propKey !== "visibility" ?
                                        <div
                                            className="keyframe"
                                            key={keyframeId + idx + 1}
                                            style={{
                                                position: "absolute",
                                                left: `${keyframe.offset * 100}%`,
                                                transform: "translate(-50%, -50%)",
                                            }}
                                            onClick={() => handleTooltipToggle(keyframeId)}
                                        ><img className="keyframeIcon" src={keyframeIcon} alt="keyframe" style={{ zIndex: 0 }} /></div> : null
                                    }

                                    {/* In-Between Transition Tooltip (Compact Layout) */}
                                    {nextKeyframe && (
                                        <div>
                                            {activeTooltipId === segmentId && (
                                                <div
                                                    className={tooltipDirection === "down" ? "tooltip-inbetween" : "tooltip-inbetween-up"}
                                                    style={{
                                                        zIndex: 10000,
                                                        left: `${(keyframe.offset * 100) + ((nextKeyframe.offset - keyframe.offset) * 50)}%`,
                                                    }}
                                                >
                                                    {propKey !== 'visibility' ?
                                                        <div>
                                                            <div><strong>Animation Interpolation</strong></div>
                                                            {keyframe.easing && <div><strong>Easing:</strong> {keyframe.easing}</div>}
                                                            <div><strong>Duration:</strong> {formatNumber(nextKeyframe.time - keyframe.time)}ms</div>
                                                        </div> : null
                                                    }


                                                    {/* Fill Interpolation */}
                                                    {propKey === "fill" ? (
                                                        <>
                                                            <div><strong>Start Color:</strong> {formatColor(keyframe.fill)}</div>
                                                            <div><strong>End Color:</strong> {formatColor(nextKeyframe.fill)}</div>
                                                        </>
                                                    ) : (
                                                        propKey !== "Motion Path" && propKey !== "opacity" && propKey !== "visibility" && propKey !== "Shadow" && propKey !== "Shape Morph" && propKey !== "Gradient"? (
                                                            <>
                                                                <div><strong>Start Key:</strong> {formatValue(keyframe.value, propKey)}</div>
                                                                <div><strong>End Key:</strong> {formatValue(nextKeyframe.value, propKey)}</div>
                                                                <div><strong>Delta:</strong> {calculateDelta(keyframe.value, nextKeyframe.value, propKey)}</div>
                                                            </>
                                                        ) : null

                                                    )}

                                                    {/* Offset Path Transition */}
                                                    {propKey === "Motion Path" && (
                                                        <>
                                                            {keyframe.value?.distance !== null && nextKeyframe.value?.distance !== null && (
                                                                <div>
                                                                    <div><strong>Start Key:</strong> {formatValue(keyframe.value.distance, propKey)}</div>
                                                                    <div><strong>End Key:</strong> {formatValue(nextKeyframe.value.distance, propKey)}</div>
                                                                    <div><strong>Offset Distance Delta:</strong> {formatDelta(keyframe.value.distance, nextKeyframe.value.distance, true)}</div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}

                                                    {/* Opacity Transition */}
                                                    {propKey === "opacity" && (
                                                        <>
                                                            <div><strong>Start Opacity:</strong> {formatNumber(keyframe.value?.opacity)}</div>
                                                            <div><strong>End Opacity:</strong> {formatNumber(nextKeyframe.value?.opacity)}</div>
                                                        </>
                                                    )}


                                                </div>
                                            )}
                                            <div
                                                className={`keyframe-segment ${activeTooltipId === segmentId ? "glowing" : ""}`}
                                                style={{
                                                    position: "absolute",
                                                    left: `${keyframe.offset * 100}%`,
                                                    borderRadius: '10px',
                                                    width: `${(nextKeyframe.offset - keyframe.offset) * 100}%`,
                                                    height: "100%",
                                                    cursor: "pointer",
                                                    background: propKey === "fill" ? `linear-gradient(to right, ${keyframe.fill}, ${nextKeyframe.fill})` : propKey === "opacity" ? `linear-gradient(to right, rgba(230, 60, 60,${keyframe.value.opacity}), rgba(230, 60, 60,${nextKeyframe.value.opacity}))` : propertyColors[propKey],
                                                }}
                                                onClick={() => handleTooltipToggle(segmentId)}
                                            ></div>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <React.Fragment>
            {motionData || hasAnimatedChildren ?
                <div className="timeline-layer">
                    <div style={{ position: 'absolute', right: '32px', display: 'flex', gap: '10px' }}>
                        <button className="scroll-btn-code-search" onClick={() => searchForMotionDef(layer.nm.replace('#', '') + '_' + layer.ind)}>Motion Component</button>
                        <button className="scroll-btn-code-search" onClick={() => searchForUsageInRender(layer.nm.replace('#', '') + '_' + layer.ind)}>Motion Usage</button>
                        <button className="scroll-btn-code-search" onClick={() => { toggleSoloLayer(layer.ln, layer.ind) }}>Isolate Layer</button>
                    </div>
                    {layer.ty === 4 ?
                        <div className="timeline-layer-name" style={{ fontWeight: 'bold', marginBottom: '36px' }}>{layer.nm.replace("#", "") + "_" + layer.ind + " " + "- (Shape Layer)"}</div> : null
                    }
                    {layer.ty === 2 ?
                        <div className="timeline-layer-name" style={{ fontWeight: 'bold', marginBottom: '36px' }}>{layer.nm.replace("#", "") + "_" + layer.ind + " " + "- (Image Layer)"}</div> : null
                    }
                    {layer.ty === 1 ?
                        <div className="timeline-layer-name" style={{ fontWeight: 'bold', marginBottom: '36px' }}>{layer.nm.replace("#", "") + "_" + layer.ind + " " + "- (Solid Layer)"}</div> : null
                    }
                    {motionData && motionData.map((animData, idx) => renderKeyframes(animData.keyframes, animData.keyframes[0]?.property || `unknown-${idx}`, idx))}
                    {/* ✅ Render shapes inside shape layers */}
                    {layer.shapes && layer.shapes.map((shape, idx) => (
                        <ShapeComponent key={idx} shape={shape} formatColor={formatColor} searchForMotionDef={searchForMotionDef} searchForUsageInRender={searchForUsageInRender} calculateDelta={calculateDelta} layer={layer} fps={fps} totalFrames={totalFrames} fluentCode={fluentCode} />
                    ))}

                </div> : null
            }
        </React.Fragment>
    );
};

export default LayerComponent;
