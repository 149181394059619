




export const extractMotionData = (fluentCode, layerName) => {
    // console.log(fluentCode)
    // Ensure first letter is always capitalized (React component naming convention)
    const formattedLayerName = layerName.replace("#", "").replace(/^(.)(.*)$/, (_, first, rest) => first.toUpperCase() + rest);
    // console.log(layerName);

    // Updated regex to match multiple keyframe groups inside createMotionComponent([])
    const regex = new RegExp(
        `const\\s+${formattedLayerName}\\s*=\\s*createMotionComponent\\(\\s*\\[([\\s\\S]*?)\\]\\s*\\);`,
        "i"
    );  
    // const regex = new RegExp(
    //     `createMotionComponent\\s*\\(\\s*[\\r\\n]*\\[([\\s\\S]*?)\\]\\s*[,\\)]?`, 
    //     "i"
    // );
    

    const match = fluentCode.match(regex);

    if (!match) {
        // console.error(`Layer ${formattedLayerName} not found in Fluent Motion code.`);
        return null;
    }

    // Extract keyframe JSON block
    const keyframeBlock = match[1].trim();
    // console.log("Extracted Keyframe Block:", keyframeBlock);

    try {
        // Convert keyframes into structured data
        const parsedData = parseKeyframeBlock(keyframeBlock);

        // If no animated values exist, return null
        if (!parsedData || parsedData.length === 0 || parsedData.every((data) => data.keyframes.length === 0)) {
            return null;
        }
        // console.log("parsedData", parsedData);
        return parsedData;
    } catch (error) {
        console.error("Error parsing motion keyframes:", error);
        return null;
    }
};



const durationMappings = {
    "motionTokens.durationFast": 150,
    "motionTokens.durationFaster": 100,
    "motionTokens.durationGentle": 250,
    "motionTokens.durationNormal": 200,
    "motionTokens.durationSlow": 300,
    "motionTokens.durationSlower": 400,
    "motionTokens.durationUltraFast": 50,
    "motionTokens.durationUltraSlow": 500
};
const parseKeyframeBlock = (keyframeBlock) => {
    // console.log(keyframeBlock)
    const keyframeRegex = /keyframes:\s*\[([\s\S]*?)\],\s*duration:\s*([\w.\d]+)/g;
    let match;
    let keyframeData = [];

    while ((match = keyframeRegex.exec(keyframeBlock)) !== null) {
        const keyframesString = match[1].trim();
        let duration = match[2].trim();
        let durationToken = null;

        // If the duration is a token (e.g., "motionTokens.durationUltraSlow"), resolve it
        if (durationMappings.hasOwnProperty(duration)) {
            durationToken = duration; // Capture the original token name
            duration = durationMappings[duration]; // Convert to number
        } else {
            duration = parseFloat(duration) || 1000; // Default to 1000ms if parsing fails
        }

        // console.log(`Resolved duration: ${duration} (Token: ${durationToken || "None"})`);

        const keyframes = extractKeyframeValues(keyframesString, duration);
        keyframeData.push({ keyframes, duration, durationToken });
    }

    return keyframeData;
};

// Fix: Loop through all keyframe groups inside createMotionComponent([])
// const parseKeyframeBlock = (keyframeBlock) => {
//     const keyframeRegex = /keyframes:\s*\[([\s\S]*?)\],\s*duration:\s*([\d.]+)/g;
//     let match;
//     let keyframeData = [];

//     while ((match = keyframeRegex.exec(keyframeBlock)) !== null) {
//         const keyframesString = match[1].trim();
//         let duration = match[2].trim();

//         if (isNaN(parseFloat(duration))) {
//             console.warn("Non-numeric duration detected:", duration);
//             duration = 1000; // Default fallback if motionTokens are used
//         } else {
//             duration = parseFloat(duration);
//         }
//         console.log("keyframeBlock", keyframeBlock)
//         const keyframes = extractKeyframeValues(keyframesString, duration);
//         keyframeData.push({ keyframes, duration });
//     }

//     return keyframeData;
// };

// Fix: Properly extract all keyframe properties, including size, fill, opacity
const extractKeyframeValues = (keyframesString, duration) => {
    const keyframeRegex = /\{\s*([^}]+?)\s*\}/g;
    let match;
    let keyframes = [];

    while ((match = keyframeRegex.exec(keyframesString)) !== null) {
        const keyframeText = match[1];

        // Extract values from keyframe
        const transformMatch = keyframeText.match(/transform:\s*['"]([^'"]+)['"]/);
        const widthMatch = keyframeText.match(/width:\s*['"]?([\d.]+)px['"]?/);
        const heightMatch = keyframeText.match(/height:\s*['"]?([\d.]+)px['"]?/);
        const fillMatch = keyframeText.match(/fill:\s*['"]([^'"]+)['"]/);
        const rxMatch = keyframeText.match(/rx:\s*['"]([^'"]+)['"]/);
        const opacityMatch = keyframeText.match(/opacity:\s*([\d.]+)/);
        const visibilityMatch = keyframeText.match(/visibility:\s*['"]([^'"]+)['"]/);
        const offsetMatch = keyframeText.match(/offset:\s*([\d.]+)/);
        const easingMatch = keyframeText.match(/easing:\s*['"]?(cubic-bezier\([^)]*\)|motionTokens\.[^\s,'"]+)['"]?/);
        const offsetPathMatch = keyframeText.match(/offsetPath:\s*['"]([^'"]+)['"]/);
        const offsetDistanceMatch = keyframeText.match(/offsetDistance:\s*['"]([^'"]+)['"]/);
        let easingValue = easingMatch ? easingMatch[1].trim() : null;
        if (easingValue && easingValue.includes("motionTokens.")) {
            easingValue = easingMatch[1].trim();
        }

        const offset = offsetMatch ? parseFloat(offsetMatch[1]) : 0;
        const time = duration * offset;
      
        let keyframeEntry = {
            property: "unknown",
            transform: transformMatch ? transformMatch[1] : null,
            width: widthMatch ? parseFloat(widthMatch[1]) : null,
            height: heightMatch ? parseFloat(heightMatch[1]) : null,
            fill: fillMatch ? fillMatch[1] : null,
            opacity: opacityMatch ? parseFloat(opacityMatch[1]) : null,
            offset: offset,
            time: time,
            easing: easingValue,
            offsetPath: offsetPathMatch ? offsetPathMatch[1] : null,
            offsetDistance: offsetDistanceMatch ? offsetDistanceMatch[1] : null,
            visibility: visibilityMatch ? visibilityMatch[0]:null,
            rx: rxMatch ? rxMatch[1] : null,
            value: null,
        };

        if (visibilityMatch) {
            keyframeEntry.property = "visibility";
            keyframeEntry.value = visibilityMatch[0]
        }

        // Extract position from transform: translate(x, y)
        if (transformMatch) {
            const transformString = transformMatch[1];

            const translateMatch = transformString.match(/translate\((-?\d+(\.\d+)?)px,\s*(-?\d+(\.\d+)?)px\)/);
            if (translateMatch) {
                keyframeEntry.property = "position";
                keyframeEntry.value = {
                    x: parseFloat(translateMatch[1]) || 0,
                    y: parseFloat(translateMatch[3]) || 0
                };
            } else if (/scale\(([^)]+)\)/.test(transformString)) {
                const [, scaleX, scaleY] = transformString.match(/scale\((-?\d+(?:\.\d+)?),\s*(-?\d+(?:\.\d+)?)\)/) || [];
                keyframeEntry.property = "scale";
                keyframeEntry.value = { scaleX: parseFloat(scaleX) || 1, scaleY: parseFloat(scaleY) || 1 };
            } else if (/rotate\(([^)]+)deg\)/.test(transformString)) {
                const [, angle] = transformString.match(/rotate\((-?\d+(?:\.\d+)?)deg\)/) || [];
                keyframeEntry.property = "rotation";
                keyframeEntry.value = { angle: parseFloat(angle) || 0 };
            }
        }

        // Extract size (width & height)
        if (widthMatch && heightMatch) {
            keyframeEntry.property = "size";
            keyframeEntry.value = {
                width: parseFloat(widthMatch[1]) || 0,
                height: parseFloat(heightMatch[1]) || 0
            };
        }

        // Extract opacity
        if (opacityMatch) {
            keyframeEntry.property = "opacity";
            keyframeEntry.value = { opacity: parseFloat(opacityMatch[1]) };
        }

      

        // Extract fill color
        if (fillMatch) {
            keyframeEntry.property = "fill";
            keyframeEntry.value = fillMatch[1];
        }

        if (rxMatch) {
            keyframeEntry.property = "Border Radius";
            keyframeEntry.value = rxMatch[1];
        }

        // Extract offset path
        if (offsetPathMatch) {
            keyframeEntry.property = "Motion Path";
            keyframeEntry.value = {
                path: offsetPathMatch[1],
                distance: offsetDistanceMatch ? parseFloat(offsetDistanceMatch[1]) : null
            };
        }

      

        keyframes.push(keyframeEntry);
    }

    return keyframes;
};
