import React, { Component } from "react";
import PlayIcon from "../Images/Icons/play.svg";
import PauseIcon from "../Images/Icons/pause.svg";
import LayerComponent from "./LayerComponent";
import KeyframeTooltip from "./KeyframeTooltip";
import { extractMotionData } from "./extractMotionData";
import "./AnimationTimeline.css";
import { update } from "lodash";

class AnimationTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layers: [],
            error: false,
            totalFrames: 0,
            fps: props.frameRate || 30,
            tooltip: { visible: false, x: 0, y: 0, content: "" },
        };
    }

    componentDidMount() {
        const { animationData } = this.props;
        if (!animationData || !animationData.layers) {
            this.setState({ error: true });
            return;
        }

        this.setState({
            layers: animationData.layers,
            totalFrames: animationData.op - animationData.ip + 1,
            fps: animationData.fr,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fluentCode !== this.props.fluentCode) {
            this.setState({ fluentCode: this.props.fluentCode });
        }
        if (prevProps.animationData !== this.props.animationData) {
        }
    }

    render() {
        const { error, layers } = this.state;

        if (error) {
            return <div>Error: animation data is not available.</div>;
        }

        return (
            <div className="animation-timeline">
                {/* Play/Pause & Time Scrubber */}
                {/* <div style={{ display: "grid", gridTemplateColumns: "96px 1fr", marginBottom: "24px", marginTop:'6px' }}>
                    <div style={{ display: "flex" }} onClick={this.props.togglePlay}>
                        {this.props.isPlaying ? (
                            <img src={PauseIcon} alt="pauseIcon" />
                        ) : (
                            <img src={PlayIcon} alt="playIcon" />
                        )}
                        <div style={{ color: "white", height: "100%", marginLeft: "4px", marginTop:'16px' }}>
                            {(this.props.time*1000).toFixed(0) + "ms"}
                          
                        </div>
                    </div>
                    <input
                        id="playhead"
                        type="range"
                        min={0}
                        max={this.props.animDuration}
                        step={0.006}
                        value={this.props.time}
                        onChange={this.props.handleTimeChange}
                    />
                </div> */}

         
                {[...layers].reverse().map((layer, idx) => {
                   
                    // const motionData = extractMotionData(this.props.fluentCode, layer.nm.replace("#", "") +"_"+layer.ind);
                    
                    // if (!motionData || motionData.length === 0 || motionData.every(data => data.keyframes.length === 0)) {
                        
                    //     return null; // Skip rendering if there are no animated properties
                    // }
                    let tooltipDirection="down"
                    if(idx===0){
                    
                        tooltipDirection="up"
                    }

                    return (
                        <LayerComponent
                            key={layer.nm}
                            fluentCode={this.props.fluentCode}
                            cssCode={this.props.cssCode}
                            tooltipDirection={tooltipDirection}
                            layer={layer}
                            fps={this.state.fps}
                            toggleSoloLayer={this.props.toggleSoloLayer}
                            totalFrames={this.state.totalFrames}
                            hideTooltip={this.hideTooltip}
                        />
                    );
                })}
                
                <div style={{height:'180px'}}></div>
       
            </div>
        );
    }
}

export default AnimationTimeline;